<template>
    <nut-cell :title="value.toString()" center desc="侧向G值"></nut-cell>
    <div id="acc-g-x-chart" style="width: 100%; height: 240px"></div>
</template>

<script>
export default {
    name: "AccGX",
    data() {
        return {
            chartData: [],
            value: 0
        }
    },
    mounted() {
        const echarts = this.$echarts;

        const chart = echarts.init(document.getElementById('acc-g-x-chart'));
        chart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                data: ['侧向G值']
            },
            toolbox: {
                feature: {
                    dataView: {}
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    show: false,
                    data: []
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    min: (value) => {
                        return (Math.abs(value.min) < value.max ? -value.max * 1.05 : value.min * 1.05).toFixed(2);
                    },
                    max: (value) => {
                        return (Math.abs(value.min) < value.max ? value.max * 1.05 : -value.min * 1.05).toFixed(2);
                    }
                }
            ],
            series: [
                {
                    name: '侧向G值',
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    lineStyle: {
                        width: 0
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgb(255, 0, 135)'
                            },
                            {
                                offset: 1,
                                color: 'rgb(135, 0, 157)'
                            }
                        ])
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: []
                }
            ]
        });

        const data = this.chartData;
        const unwatchAccGX = this.$watch('$store.state.acc_g_x', function (n) {
            if (n !== null) {
                this.value = n;
                if (data.length < 28) {
                    data.push([new Date().getTime(), n]);
                } else {
                    data.shift();
                    data.push([new Date().getTime(), n]);
                }

                const x = [];
                for (const i in data) {
                    x.push(data[i][0]);
                }

                const y = [];
                for (const i in data) {
                    y.push(data[i][1]);
                }

                chart.setOption({
                    xAxis: {
                        data: x
                    },
                    series: [
                        {
                            data: y
                        }
                    ]
                });
            }
        });
    },
}
</script>