<template>
    <nut-cell desc="天气">
        <template v-slot:title>
            <span><icon-sunny fill="#000000" size="12" theme="outline"/>&nbsp;{{ weather }}</span>
        </template>
    </nut-cell>
    <nut-cell desc="温度">
        <template v-slot:title>
            <span><icon-thermometer fill="#000000" size="12" theme="outline"/>&nbsp;{{ temperature }}℃</span>
        </template>
    </nut-cell>
</template>

<script>

export default {
    name: "WeatherInfo",
    data() {
        return {
            weather: this.$store.state.weather_text,
            temperature: this.$store.state.weather_temp,
        }
    },
    mounted() {
        this.$watch('$store.state.weather_text', (n) => {
            this.weather = n
        });
        this.$watch('$store.state.weather_temp', (n) => {
            this.temperature = n
        });
    }
}
</script>