<template>
    <nut-navbar
            :border="true"
            :fixed="true"
            :placeholder="true"
            :safe-area-inset-top="true"
    >
        <template #left>
            <AdminBlock/>
        </template>


    </nut-navbar>
</template>

<script>
import AdminBlock from "@/components/admin/AdminBlock.vue";

export default {
    name: "AdminTop",
    components: {AdminBlock},

    beforeMount() {
        const unwatchUserToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                this.state = '已登录';
                unwatchUserToken();

            }
        });
    }
}
</script>