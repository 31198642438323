<template>
    <nut-navbar
            :border="true"
            :fixed="true"
            :placeholder="true"
            :safe-area-inset-top="true"
            @on-click-back="this.$store.commit('setDetailState', true)"
    >
        <template #left>
            <icon-info fill="#333" size="18" theme="outline"/>
        </template>

        <template #content>
            <nut-tabs>
                <nut-tab-pane :title="state"></nut-tab-pane>
            </nut-tabs>
        </template>

        <template #right>
            <nut-tag type="primary">{{ monitorCount }}</nut-tag>&nbsp;
            <icon-database-network-point :fill="mqState" size="18" theme="outline"/>
        </template>
    </nut-navbar>
</template>

<script>
export default {
    name: "Nav",
    data() {
        return {
            state: '激活中',
            mqState: '#333',
            monitorCount: 0,
        }
    },
    mounted() {
        const unwatchCheckState = this.$watch('$store.state.check_state', function (n) {
            if (n === 'loading') {
                this.state = '自检中';
                unwatchCheckState();
            }
        });
        const unwatchMonitorState = this.$watch('$store.state.monitor_state', function (n) {
            if (n === true) {
                this.state = '监测中';
                unwatchMonitorState();
            }
        });
        const unwatchGeoTs = this.$watch('$store.state.geo_ts', function (n) {
            this.monitorCount++;
        });
        const unwatchMqState = this.$watch('$store.state.mq_state', function (n) {
            if (n === true) {
                this.mqState = '#4fc08d';
            } else {
                this.mqState = '#fa2c19';
            }
        });
    }
}
</script>