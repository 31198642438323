<template>
    <nut-cell desc="格式化地址">
        <template v-slot:title>
            <span><icon-user-positioning fill="#000000" size="12" theme="outline"/>&nbsp;{{ formattedAddr }}</span>
        </template>
    </nut-cell>
    <nut-cell desc="城市">
        <template v-slot:title>
            <span><icon-city fill="#000000" size="12" theme="outline"/>&nbsp;{{ city }}</span>
        </template>
    </nut-cell>
    <nut-cell desc="道路">
        <template v-slot:title>
            <span><icon-road fill="#000000" size="12" theme="outline"/>&nbsp;{{ road }}</span>
        </template>
    </nut-cell>
    <nut-cell desc="地址">
        <template v-slot:title>
            <span><icon-map-distance fill="#000000" size="12" theme="outline"/>&nbsp;{{ addr }}</span>
        </template>
    </nut-cell>
</template>

<script>

export default {
    name: "LocInfo",
    data() {
        return {
            formattedAddr: this.$store.state.loc_format_addr,
            city: this.$store.state.loc_city,
            road: this.$store.state.loc_road,
            addr: this.$store.state.loc_addr,
        }
    },
    mounted() {
        this.$watch('$store.state.loc_format_addr', (n) => {
            this.formattedAddr = n
        });
        this.$watch('$store.state.loc_city', (n) => {
            this.city = n
        });
        this.$watch('$store.state.loc_road', (n) => {
            this.road = n
        });
        this.$watch('$store.state.loc_addr', (n) => {
            this.addr = n
        });
    }
}
</script>