<template>
    <nut-popup v-model:visible="loginPopupDisplay" :style="{ height: '60%' }" position="bottom">
        <nut-form>
            <nut-form-item label="管理员号">
                <nut-input v-model="id" class="nut-input-text" placeholder="请输入管理员号" type="text"/>
            </nut-form-item>
            <nut-form-item label="密码">
                <nut-input v-model="password" class="nut-input-text" placeholder="请输入密码" type="password"/>
            </nut-form-item>
        </nut-form>
        <nut-button @click="login">确认登录</nut-button>
    </nut-popup>

    <nut-cell type="primary" @click="reqLogin">登录</nut-cell>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie'

export default {
    name: "AdminLogin",
    data() {
        return {
            id: null,
            password: null,
            loginPopupDisplay: false
        }
    },
    methods: {
        reqLogin() {
            this.loginPopupDisplay = true;
        },
        login() {
            axios({
                method: 'post',
                url: 'https://8.130.14.173/admin/adminLogin',
                params: {
                    'id': this.id,
                    'password': this.password,
                }
            })
                .then((response) => {
                    alert('登陆成功')
                    this.$store.commit('setAdminToken', response.headers.authorization);
                    Cookies.set('admin_token', response.headers.authorization, {expires: 7})
                })
                .catch((error) => {
                    console.log('登陆失败')
                    console.log(error)
                });
        }
    },
    mounted() {
        const unwatchAdminToken = this.$watch('$store.state.admin_token', function (n) {
            console.log('监听到Token变动' + n)
        });
        if (Cookies.get('admin_token') !== undefined && Cookies.get('admin_token') != null && Cookies.get('admin_token') !== '') {
            this.$store.commit('setAdminToken', Cookies.get('admin_token'));
        }
    }
}
</script>

<style scoped>

</style>