<template>
    <nut-cell v-if="visible">
        <nut-tabs v-model="tabState.value" :title-scroll="true" size="large" style="width: 100%" type="smile">
            <nut-tab-pane style="padding: 0;" title="个人信息">
                <icon-help fill="#333" size="20" theme="outline" @click="openTourInfo"/>
                <UserFind/>
            </nut-tab-pane>
            <nut-tab-pane style="padding: 0;" title="联系人信息">
                <ContactFind/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from 'vue';
import UserFind from "@/components/user/UserTap/info/UserFind.vue";
import ContactFind from "@/components/user/UserTap/info/ContactFind.vue";

export default {
    name: "UserInfo",
    components: {ContactFind, UserFind},
    data() {
        return {
            visible: true
        }
    },
    methods: {
        openTourInfo() {
            this.$emit("openTourInfo")
        },
    },
    setup() {
        const tabState = reactive({
            value: '0',
        });

        return {tabState};
    },


    mounted() {
        const unwatchUserInfoTab = this.$watch('$store.state.info_tab', function (n) {
            this.visible = !!n;

        });


    },

}

</script>

<style scoped>

</style>