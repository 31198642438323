<template>
  <!--遍历Alarm-->
    <nut-cell
            v-for="alarm in alarm_data"
            :key="alarm.label"
            :desc="alarm.ref_timestamp"
            @click="AlarmInfo(alarm.itinerary_uuid,alarm.timestamp,alarm.ref_timestamp,alarm.recording,alarm.is_alerted,alarm.is_canceled)"
    >
        <template #icon>
            <icon-protect v-if="alarm.is_alerted===alarm.is_canceled" fill="green" size="24" theme="outline"/>

            <icon-attention v-if="alarm.is_alerted!==alarm.is_canceled" fill="red" size="24" theme="outline"/>
        </template>
    </nut-cell>

  <!--行程预警弹出页-->
    <nut-popup v-model:visible="AlarmInfoPopupDisplay" :style="{height: '60%',width:'90%'}" :teleport-disable="true"
               round>
        <nut-cell :desc="alarm_uuid" title="行程UUID">
            <template #icon>
                <icon-id-card fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="alarm_timestamp" title="可能事故时间">
            <template #icon>
                <icon-ambulance fill="red" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="alarm_ref_timestamp" title="预警时间">
            <template #icon>
                <icon-remind-disable fill="red" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="alarm_recording" title="录音编号">
            <template #icon>
                <icon-microphone fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>

    </nut-popup>
</template>

<script>
import axios from "axios";

export default {
    name: "ItineraryAlarmFind",
    data() {
        return {
            alarm_data: [
                {
                    itinerary_uuid: null,
                    timestamp: null,
                    ref_timestamp: null,
                    recording: null,
                    is_alerted: null,
                    is_canceled: null,
                }
            ],
            AlarmInfoPopupDisplay: false,
            alarm_uuid: null,
            alarm_timestamp: null,
            alarm_ref_timestamp: null,
            alarm_alerted: null,
            alarm_canceled: null,
            alarm_recording: null,
            noneEndDisplay: false,
        }
    },
    methods: {
        AlarmInfo(itinerary_uuid, timestamp, ref_timestamp, recording, is_alerted, is_canceled) {
            this.alarm_uuid = itinerary_uuid
            this.alarm_timestamp = timestamp
            this.alarm_ref_timestamp = ref_timestamp
            this.alarm_recording = recording
            this.alarm_alerted = is_alerted
            this.alarm_canceled = is_canceled
            this.AlarmInfoPopupDisplay = true
        },

        getAlarmInfo() {

            axios({
                method: 'get',
                url: 'https://8.130.14.173/user/UserAlarm',
                headers: {
                    authorization: this.$store.state.user_token,
                },
            })
                .then((response) => {
                    this.$store.commit('setAlarmData', response.data.data)
                    this.alarm_data = this.$store.state.alarm_data
                })
                .catch((error) => {
                    console.log('查询失败')
                    console.log(error)
                });
        }
    },
    mounted() {
        this.getAlarmInfo()
    },
    beforeMount() {

        const unwatchContactToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                unwatchContactToken();
            }
        });
    },

}
</script>

<style scoped>

</style>