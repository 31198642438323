<template>
    <nut-tabbar :bottom="true" :safe-area-inset-bottom="true" @tab-switch="tabSwitch">
        <nut-tabbar-item tab-title="状态">
            <template #icon="props">
                <icon-ring v-if="props.active === true" fill="#2c68ff" size="22" theme="outline"/>
                <icon-ring v-if="props.active === false" fill="#000000" size="22" theme="outline"/>
            </template>
        </nut-tabbar-item>
        <nut-tabbar-item tab-title="地理信息">
            <template #icon="props">
                <icon-map-road v-if="props.active === true" fill="#2c68ff" size="22" theme="outline"/>
                <icon-map-road v-if="props.active === false" fill="#000000" size="22" theme="outline"/>
            </template>
        </nut-tabbar-item>
        <nut-tabbar-item tab-title="传感数据">
            <template #icon="props">
                <icon-average v-if="props.active === true" fill="#2c68ff" size="22" theme="outline"/>
                <icon-average v-if="props.active === false" fill="#000000" size="22" theme="outline"/>
            </template>
        </nut-tabbar-item>
        <nut-tabbar-item tab-title="其他信息">
            <template #icon="props">
                <icon-dashboard v-if="props.active === true" fill="#2c68ff" size="22" theme="outline"/>
                <icon-dashboard v-if="props.active === false" fill="#000000" size="22" theme="outline"/>
            </template>
        </nut-tabbar-item>
    </nut-tabbar>
</template>

<script>

import {useStore} from "vuex";

export default {
    name: "Tab",
    setup() {
        const store = useStore();

        function hideAll() {
            store.commit('setStatusTab', false);
            store.commit('setSensorTab', false);
            store.commit('setGeoTab', false);
            store.commit('setOtherTab', false);
        }

        function tabSwitch(item, index) {
            switch (index) {
                case 0:
                    hideAll();
                    store.commit('setStatusTab', true);
                    break;
                case 1:
                    hideAll();
                    store.commit('setGeoTab', true);
                    break;
                case 2:
                    hideAll();
                    store.commit('setSensorTab', true);
                    break;
                case 3:
                    hideAll();
                    store.commit('setOtherTab', true);
                    break;
            }
        }

        return {
            tabSwitch,
        };
    }
}
</script>