<template>
    <nut-popup v-model:visible="scanPopup" :style="{ height: '60%' }" position="top">
        <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit"/>
    </nut-popup>

    <nut-navbar
            :border="true"
            :fixed="true"
            :placeholder="true"
            :safe-area-inset-top="true"
            title="行程查询"
    >
    </nut-navbar>

    <nut-row>
        <nut-col :span="6" style="text-align: center">
            <nut-button size="small" type="primary" @click="scan">
                <icon-viewfinder fill="#FFFFFF" size="14" theme="outline"/>&nbsp;扫描行程二维码
            </nut-button>
        </nut-col>
        <nut-col :span="10" style="text-align: center">
            <nut-form style="margin-top: -14px; margin-bottom: -18px">
                <nut-input v-model="uuid" class="nut-input-text" placeholder="请输入行程ID" type="text"/>
            </nut-form>
        </nut-col>
        <nut-col :span="4">&nbsp;</nut-col>
        <nut-col :span="4" style="text-align: left">
            <nut-button size="small" type="primary" @click="query">
                <icon-database-search fill="#FFFFFF" size="14" theme="outline"/>&nbsp;查询
            </nut-button>
        </nut-col>
    </nut-row>
    <nut-divider dashed/>

    <DetailTable/>
</template>

<script>
import {QrcodeStream} from "vue-qrcode-reader/src";
import DetailTable from "@/components/i/DetailTable.vue";

export default {
    name: "IDetail",
    components: {
        DetailTable,
        QrcodeStream
    },
    data() {
        return {
            uuid: null,
            scanPopup: false,
            camera: 'off'
        }
    },
    methods: {
        scan() {
            this.scanPopup = true;
            this.camera = 'auto';
        },
        query() {
            this.$store.commit('setIUuid', this.uuid);
        },
        async onInit(promise) {
            try {
                await promise
            } catch (e) {
                console.error('QrCode Scan Err, ', e)
            }
        },

        onDecode(content) {
            this.uuid = content;
            this.scanPopup = false;
            this.camera = 'off';
        }
    }
}
</script>