<template>
    <nut-navbar
            :border="true"
            :fixed="true"
            :placeholder="true"
            :safe-area-inset-top="true"
            @on-click-back="this.$store.commit('setDetailState', true)"
    >
        <template #left>
            <Block/>
        </template>

        <template #content>
            <nut-tabs>
                <nut-tab-pane :title="state"></nut-tab-pane>
            </nut-tabs>
        </template>

        <template #right>
            <icon-help fill="#333" size="20" theme="outline" @click="openTourTop"/>
        </template>


    </nut-navbar>
</template>

<script>
import Block from "@/components/user/block/Block.vue";

export default {
    name: "UserTop",
    components: {Block},

    methods: {
        openTourTop() {
            this.$emit("openTour")
        }
    },
    data() {
        return {
            id: null,
            state: '未登录',
        }
    },
    beforeMount() {
        const unwatchUserToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                this.state = '已登录';
                unwatchUserToken();

            }
        });
    }
}
</script>