<template>
    <div>
        Welcome,{{ id }}
    </div>
</template>

<script>
export default {
    name: "Welcome",
    data() {
        return {
            id: null,
        }
    },
    beforeMount() {
        this.id = this.$store.state.user_id
    }
}
</script>

<style scoped>

</style>