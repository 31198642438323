<template>
    <nut-cell v-if="visible">
        <nut-tabs v-model="tabState.value" :title-scroll="true" size="large" style="width: 100%" type="smile">
            <nut-tab-pane style="padding: 0;" title="天气信息">
                <WeatherInfo/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import WeatherInfo from "@/components/vehicle/tab/other/WeatherInfo.vue";

export default {
    name: "OtherInfo",
    components: {WeatherInfo},
    data() {
        return {
            visible: false
        }
    },
    setup() {
        const tabState = reactive({
            value: '0',
        });

        return {tabState};
    },
    mounted() {
        const unwatchOtherTab = this.$watch('$store.state.other_tab', function (n) {
            this.visible = !!n;
        });
    }
}
</script>