<template>
    <nut-cell v-if="visible">
        <nut-tabs v-model="tabState.value" :title-scroll="true" size="large" style="width: 100%" type="smile">
            <nut-tab-pane style="padding: 0;" title="行程信息">
                <ItineraryFind/>
            </nut-tab-pane>
            <nut-tab-pane style="padding: 0;" title="预警信息">
                <ItineraryAlarmFind/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import ItineraryFind from "@/components/user/UserTap/iti/ItineraryFind.vue";
import ItineraryAlarmFind from "@/components/user/UserTap/iti/ItineraryAlarmFind.vue";


export default {
    name: "Iti",
    components: {ItineraryAlarmFind, ItineraryFind},
    data() {
        return {
            visible: false
        }
    },
    setup() {
        const tabState = reactive({
            value: '0',
        });

        return {tabState};
    },
    mounted() {
        const unwatchItiTab = this.$watch('$store.state.iti_tab', function (n) {
            this.visible = !!n;
        });
    }
}
</script>

<style scoped>

</style>