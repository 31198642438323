<template>
    <nut-popup v-model:visible="sendMailPopup" :style="{ height: '20%' }" position="top">
        <nut-cell>
            <span>请输入欲接收行程基本信息的邮箱地址</span>
            <nut-input
                    v-model="emailAddr"
                    clearable
                    placeholder="邮箱地址"
            >
            </nut-input>
        </nut-cell>
        <nut-button block size="small" type="info" @click="sendMail">
            <icon-accept-email fill="#FFFFFF" size="12" theme="outline"/>
            确认发送
        </nut-button>
    </nut-popup>

    <nut-cell>
        <h1 v-if="state==null" style="color: black">
            <icon-more-three fill="#000000FF" size="24" theme="outline"/>&nbsp;等待行程结束
        </h1>
        <h1 v-if="state===true" style="color: green">
            <icon-check-one fill="#008000FF" size="24" theme="outline"/>&nbsp;行程结束成功
        </h1>
        <h1 v-if="state===false" style="color: red">
            <icon-close-one fill="#FF0000FF" size="24" theme="outline"/>&nbsp;行程结束失败
        </h1>
    </nut-cell>
    <nut-cell desc="https://vmas.rexue.plus/i/" title="查询本次行程信息？"></nut-cell>
    <nut-cell><span>请使用其他设备访问上方地址，通过输入下方行程ID或扫描二维码查询行程详情</span>
        <nut-button size="mini" type="info" @click="sendMailPopup=true">发送到邮箱</nut-button>
    </nut-cell>
    <ItineraryUuidQr/>
</template>

<script>
import axios from "axios";
import ItineraryUuidQr from "@/components/vehicle/detail/ItineraryUuidQr.vue";
import {showNotify} from '@nutui/nutui';
import '@nutui/nutui/dist/packages/notify/style';

export default {
    name: "IFinish",
    components: {ItineraryUuidQr},
    setup() {
        const primaryNotify = (msg) => {
            showNotify.primary(msg);
        };
        const successNotify = (msg) => {
            showNotify.success(msg);
        };
        const errorNotify = (msg) => {
            showNotify.danger(msg);
        };
        const warningNotify = (msg) => {
            showNotify.warn(msg);
        };
        return {
            primaryNotify,
            successNotify,
            errorNotify,
            warningNotify,
        }
    },
    data() {
        return {
            state: null,
            sendMailPopup: false,
            emailAddr: ''
        }
    },
    methods: {
        sendMail() {
            if (!/^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(this.emailAddr)) {
                this.errorNotify('邮箱格式错误！');
                return;
            }
            axios({
                method: 'post',
                url: 'https://8.130.14.173/vehicle/itinerary/mail',
                headers: {
                    authorization: this.$store.state.token,
                },
                params: {
                    'id': this.$store.state.itinerary_uuid,
                    'email': this.emailAddr
                }
            })
                .then((response) => {
                    this.successNotify('邮件发送成功！');
                    this.sendMailPopup = false;
                })
                .catch((error) => {
                    if (error.response === undefined) {
                        this.errorNotify('邮件发送请求失败！');
                    }
                    this.errorNotify('邮件发送失败：' + error.response.data.msg);
                });
        }
    },
    mounted() {
        axios({
            method: 'patch',
            url: 'https://8.130.14.173/vehicle/itinerary/finish',
            headers: {
                authorization: this.$store.state.token,
            },
            params: {
                'id': this.$store.state.itinerary_uuid,
            }
        })
            .then((response) => {
                this.state = true;
            })
            .catch((error) => {
                this.state = false;
            });
    }
}
</script>