<template>
    <div>
        <nut-cell id="targetPhone" :desc="phone" title="手机号" @click="reqUpdUserPhone(phone)">
            <template #icon>
                <icon-iphone fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-popup v-model:visible="updUserPhonePopupDisplay" :style="{ height: '20%',width:'80%' }"
                   :teleport-disable="true"
                   round>
            <nut-input v-model="select_phone" placeholder="电话号码"/>
            <nut-button color="#1368D7" size="small" style="margin-left: 110px;margin-top: 40px" @click="updUserPhone">
                修改
            </nut-button>
        </nut-popup>

        <nut-cell id="targetLpn" :desc="lpn" title="车牌号" @click="reqUpdUserLpn(lpn)">
            <template #icon>
                <icon-car fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-popup v-model:visible="updUserLpnPopupDisplay" :style="{ height: '20%',width:'80%' }"
                   :teleport-disable="true"
                   round>
            <nut-input v-model="select_lpn" placeholder="车牌号"/>
            <nut-button color="#1368D7" size="small" style="margin-left: 110px;margin-top: 40px" @click="updUserLpn">
                修改
            </nut-button>
        </nut-popup>

        <nut-cell id="targetAddress" :desc="address" title="地址" @click="showAddress">
            <template #icon>
                <icon-city fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <!--        <nut-popup v-model:visible="updUserAddressPopupDisplay" round :style="{ height: '20%',width:'80%' }" :teleport-disable="true">
                    <nut-input v-model="select_address" placeholder="地址"/>
                    <nut-button @click="updUserAddress" size="small" color="#1368D7" style="margin-left: 110px;margin-top: 40px">修改</nut-button>
                </nut-popup>-->
        <nut-address
                v-model:visible="showPopup"
                :city="city"
                :country="country"
                :province="province"
                :town="town"
                custom-address-title="请选择所在地区"
                @change="onChange1"
                @close="close1"
        ></nut-address>
        <nut-popup v-model:visible="updUserAddressPopupDisplay" :style="{ height: '20%',width:'80%' }"
                   :teleport-disable="true"
                   round>
            <nut-input v-model="select_address" placeholder="选中地址"/>
            <nut-input v-model="select_secAddress" placeholder="详细地址"/>
            <nut-button color="#1368D7" size="small" style="margin-left: 110px;margin-top: 40px"
                        @click="updUserAddress">修改
            </nut-button>
        </nut-popup>


        <nut-cell id="targetVehicleType" :desc="vehicle_type" title="车辆类型" @click="reqUpdUserVehicleType">
            <template #icon>
                <icon-steering-wheel fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-popup v-model:visible="updUserVehicleTypePopupDisplay" position="bottom">
            <nut-picker
                    v-model="select_vehicle_type"
                    :columns="typeColumns"
                    :v-model:value=null
                    option-height="20"
                    title="请选择车辆类型"
                    @cancel="updUserVehicleTypePopupDisplay=false"
                    @confirm="updUserVehicleType"
            >

            </nut-picker>
        </nut-popup>

        <nut-cell id="targetUsingNature" :desc="using_nature" title="使用性质" @click="reqUpdUserUsingNature()">
            <template #icon>
                <icon-more-four fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-popup v-model:visible="updUserUsingNaturePopupDisplay" position="bottom">
            <nut-picker
                    v-model="select_using_nature"
                    :columns="usingColumns"
                    :v-model:value=null
                    option-height="20"
                    title="请选择使用性质"
                    @cancel="updUserUsingNaturePopupDisplay=false"
                    @confirm="updUserUsingNature"
            >

            </nut-picker>
        </nut-popup>


    </div>
</template>

<script>
import axios from "axios";
import {useStore} from "vuex";
import area from "@/assets/data/area.json";

export default {
    name: "UserFind",
    data() {
        return {
            showPopup: false,
            area: require('@/assets/data/area.json'),
            province: area["00"],
            city: [],
            country: [],
            town: [],
            phone: null,
            address: null,
            lpn: null,
            vehicle_type: null,
            using_nature: null,
            select_phone: null,
            select_lpn: null,
            select_address: null,
            select_secAddress: null,
            select_vehicle_type: null,
            select_using_nature: null,
            updUserPhonePopupDisplay: false,
            updUserLpnPopupDisplay: false,
            updUserAddressPopupDisplay: false,
            updUserVehicleTypePopupDisplay: false,
            updUserUsingNaturePopupDisplay: false,
            contacts: [
                {
                    remark: null,
                    phone: null,
                }
            ],
            typeColumns: [
                {text: '乘用车', value: '0'},
                {text: '摩托车', value: '1'},
                {text: '货车', value: '2'},
                {text: '三轮汽车', value: '3'},
                {text: '拖拉机', value: '4'},
                {text: '其他机动车', value: '5'},
                {text: '汽车列车', value: '6'},
                {text: '低速汽车', value: '7'},
                {text: '其他汽车', value: '8'}
            ],
            usingColumns: [
                {text: '非生产经营车辆', value: '0'},
                {text: '生产经营车辆', value: '1'},
            ],
        }

    },


    methods: {

        useStore,
        showAddress() {
            this.showPopup = true
        },
        onChange1(cal) {
            if (cal.custom === "province") {
                this.city = area[cal.value.id]
            }

            const name = this[cal.next]
            if (name.length < 1) {
                this.showPopup = false;
            }
        },
        close1(val) {
            this.text1 = val.data.addressStr
            this.select_address = this.text1
            this.updUserAddressPopupDisplay = true
        },
        getUserInfo() {
            axios({
                method: 'get',
                url: 'https://8.130.14.173/user/reqUser',
                headers: {
                    authorization: this.$store.state.user_token,
                },
            })
                .then((response) => {
                    this.$store.commit('setUserId', response.data.data.id)
                    this.$store.commit('setUserPhone', response.data.data.phone)
                    this.$store.commit('setUserAddress', response.data.data.address)
                    this.$store.commit('setUserLpn', response.data.data.lpn)
                    this.$store.commit('setVehicleType', response.data.data.vehicle_type)
                    this.$store.commit('setUsingNature', response.data.data.using_nature)
                    this.$store.commit('setContacts', response.data.data.contacts)
                    this.phone = this.$store.state.user_phone
                    this.address = this.$store.state.user_address
                    this.lpn = this.$store.state.user_lpn
                    const vehicleMap = {
                        boolean: {
                            0: '乘用车',
                            1: '摩托车',
                            2: '货车',
                            3: '三轮汽车',
                            4: '拖拉机',
                            5: '其他机动车',
                            6: '汽车列车',
                            7: '低速汽车',
                            8: '其他汽车'
                        }
                    }
                    this.vehicle_type = vehicleMap.boolean[this.$store.state.vehicle_type]
                    const usingMap = {boolean: {false: '非生产经营车辆', true: '生产经营车辆'}}
                    this.using_nature = usingMap.boolean[this.$store.state.using_nature]
                })
                .catch((error) => {
                    console.log('查询失败')
                    console.log(error)
                });
        },
        phoneCheck() {
            if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.select_phone)) {
                console.log('电话号码格式错误，请重新输入');
                alert('电话号码格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },
        lpnCheck() {
            const plateNumber = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1})$/
            if (!plateNumber.test(this.select_lpn)) {
                console.log('车牌号格式错误，请重新输入');
                alert('车牌号格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },

        reqUpdUserPhone(phone) {
            this.select_phone = phone
            this.updUserPhonePopupDisplay = true
        },
        reqUpdUserLpn(lpn) {
            this.select_lpn = lpn
            this.updUserLpnPopupDisplay = true
        },
        reqUpdUserAddress() {
            this.updUserAddressPopupDisplay = true
        },
        reqUpdUserVehicleType() {
            this.updUserVehicleTypePopupDisplay = true
        },
        reqUpdUserUsingNature() {
            this.updUserUsingNaturePopupDisplay = true
        },

        updUserPhone() {
            if (!this.phoneCheck()) {
                return;
            }

            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/modify/phone',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'phone': this.select_phone,
                }
            })
                .then((response) => {
                    console.log(response)
                    this.updUserPhonePopupDisplay = false
                    this.phone = this.select_phone
                })
                .catch((error) => {
                    console.log('修改失败')
                    console.log(error)
                });
        },
        updUserLpn() {
            if (!this.lpnCheck()) {
                return;
            }

            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/modify/lpn',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'lpn': this.select_lpn,
                }
            })
                .then((response) => {
                    console.log(response)
                    this.updUserLpnPopupDisplay = false
                    this.lpn = this.select_lpn
                })
                .catch((error) => {
                    console.log('修改失败')
                    console.log(error)
                });
        },
        updUserAddress() {
            this.select_secAddress = this.select_address + this.select_secAddress

            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/modify/address',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'address': this.select_secAddress,
                }
            })
                .then((response) => {
                    console.log(response)
                    this.updUserAddressPopupDisplay = false
                    this.address = this.select_secAddress
                    alert('修改成功')
                })
                .catch((error) => {
                    console.log('修改失败')
                    console.log(error)
                });
        },
        updUserVehicleType() {
            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/modify/vehicleType',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'vehicle_type': Number(this.select_vehicle_type),
                }
            })
                .then((response) => {

                    console.log(response)
                    this.updUserVehicleTypePopupDisplay = false
                    this.vehicle_type = this.select_vehicle_type
                    const vehicleMap = {
                        boolean: {
                            0: '乘用车',
                            1: '摩托车',
                            2: '货车',
                            3: '三轮汽车',
                            4: '拖拉机',
                            5: '其他机动车',
                            6: '汽车列车',
                            7: '低速汽车',
                            8: '其他汽车'
                        }
                    }
                    this.vehicle_type = vehicleMap.boolean[this.vehicle_type]

                })
                .catch((error) => {
                    console.log('修改失败')
                    console.log(error)
                });
        },
        updUserUsingNature() {

            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/modify/usingNature',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'using_nature': Number(this.select_using_nature),
                }
            })
                .then((response) => {

                    console.log(response)
                    this.updUserUsingNaturePopupDisplay = false
                    this.using_nature = this.select_using_nature
                    const usingMap = {boolean: {0: '非生产经营车辆', 1: '生产经营车辆'}}
                    this.using_nature = usingMap.boolean[this.using_nature]
                })
                .catch((error) => {
                    console.log('修改失败')
                    console.log(error)
                });
        },


        reqDelContact(phone) {
            this.select_phone = phone
            this.delContactPopupDisplay = true
        },

    },
    beforeMount() {

        const unwatchUserToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                unwatchUserToken();

            }
        });


    },
    mounted() {
        this.getUserInfo();

    }
}
</script>

<style scoped>

</style>