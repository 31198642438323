<template>
    <nut-popup v-model:visible="visible" :close-on-click-overlay="false" :style="{ height: '70%' }" position="top">
        <h2 style="text-align: center">
            <icon-caution fill="#FA2C19" size="22" theme="outline"/>&nbsp;等待应急中心处理响应
        </h2>
        <br>
        <div style="text-align: center">
            <nut-animate :loop='true' type='float'>
                <nut-circle-progress :progress="100" :radius="100" :stroke-width="10" color="#FA2C19"
                                     style="display: inline-block">
                    <nut-countdown ref="awaitCountdown" :auto-start="false" format="ss"
                                   style="font-size: 100px; font-weight: bold; --nut-countdown-display: block"
                                   time="60000"
                                   @on-end="this.$store.commit('setAlertState', 3)"/>
                </nut-circle-progress>
            </nut-animate>
            <br>
        </div>
        <div style="margin-top: 40px; text-align: center">
            <nut-button shape="square" style="height: 100px; width: 100px; font-size: 30px"
                        type="danger"
                        @click="this.$store.commit('setAlertState', 3)">警报确认
            </nut-button>&nbsp;
            <nut-button shape="square" style="height: 100px; width: 100px; font-size: 30px"
                        type="primary"
                        @click="this.$store.commit('setAlertState', 0)">警报取消
            </nut-button>
        </div>
    </nut-popup>
</template>
<script>
import {ref} from "vue";

export default {
    name: "AlarmAwait",
    setup() {
        const awaitCountdown = ref(null);
        const startAwaitCountdown = () => {
            if (awaitCountdown.value !== null) {
                awaitCountdown.value.start();
            }
        };
        const resetAwaitCountdown = () => {
            if (awaitCountdown.value !== null) {
                awaitCountdown.value.reset();
            }
        };
        return {awaitCountdown, startAwaitCountdown, resetAwaitCountdown};
    },
    data() {
        return {
            visible: false
        }
    },
    mounted() {
        const unwatchAlertState = this.$watch('$store.state.alert_state', (n) => {
            if (n === 2) {
                this.$store.commit('setWarningState', true);
                this.visible = true;
                setTimeout(() => {
                    this.startAwaitCountdown();
                }, 500);
            }
            if (n === 0 || n === 1 || n === 3) {
                this.visible = false;
                this.resetAwaitCountdown();
            }
        })
    }
}
</script>