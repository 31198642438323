<template>
    <nut-table :columns="columns" :data="data" :striped="true" :summary="summary"></nut-table>
</template>

<script>
import {Button} from '@nutui/nutui';
import {h} from "@vue/runtime-core";
import moment from "moment/moment";
import WarningCountdown from "@/components/src/WarningCountdown.vue";
import WarningAudio from "@/components/src/WarningAudio.vue";

export default {
    name: "WarningData",
    methods: {
        add(itinerary_uuid, timestamp, ref_timestamp, recording_res) {
            this.data.unshift(
                {
                    itinerary_uuid: itinerary_uuid,
                    timestamp_ori: timestamp,
                    ref_timestamp_ori: ref_timestamp,
                    ref_timestamp: moment(ref_timestamp).format("HH:mm:ss:SSS"),
                    timestamp: moment(timestamp).format("HH:mm:ss:SSS"),
                    countdown: () => {
                        return h(
                            WarningCountdown,
                            {
                                timestamp: timestamp,
                            },
                            {
                                default: () => ''
                            }
                        );
                    },
                    recording: () => {
                        return h(
                            WarningAudio,
                            {
                                url: recording_res
                            },
                            {
                                default: () => ''
                            }
                        )
                    },
                    action: () => {
                        return h(
                            Button,
                            {
                                onClick: () => {
                                    this.$store.commit('setSrcWarningDetail',
                                        {
                                            itinerary_uuid: itinerary_uuid,
                                            timestamp: timestamp,
                                            ref_timestamp: ref_timestamp
                                        }
                                    );
                                },
                                size: 'mini',
                                type: 'primary'
                            },
                            {
                                default: () => "详情"
                            }
                        );
                    }
                }
            )
        },

    },
    data() {
        return {
            columns: [
                {
                    title: '事故时间',
                    key: 'ref_timestamp',
                    align: 'center'
                },
                {
                    title: '预警时间',
                    key: 'timestamp',
                    align: 'center'
                },
                {
                    title: '反馈录音',
                    key: 'recording',
                    align: 'center'
                },
                {
                    title: '响应时间',
                    key: 'countdown',
                    align: 'center'
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center'
                }
            ],
            data: [],
            summary: () => {
                return {
                    value: '当前需介入处理' + this.data.length + '条预警数据',
                    colspan: 5
                };
            }
        }
    },
    mounted() {
        setInterval(() => {
            for (const key in this.data) {
                if (moment(this.data[key].timestamp_ori).add(60, 'seconds').isBefore(moment())) {
                    this.data.splice(Number(key), 1);
                }
            }
        }, 1000)
        const unwatchSrcDeleteFlag = this.$watch('$store.state.src_delete_flag', function (n) {
            for (const key in this.data) {
                if (this.data[key].itinerary_uuid === n.id && this.data[key].ref_timestamp_ori === n.ts) {
                    this.data.splice(key, 1);
                    if (this.data.length === 0) {
                        this.$store.commit('setSrcDetailDisplay', null);
                    }
                    break;
                }
            }
        });
        const unwatchSrcWarningLatest = this.$watch('$store.state.src_warning_latest', function (n) {
            this.add(n.info.itinerary_uuid, n.info.timestamp, n.info.ref_timestamp, n.info.recording_res);
        });
    }
}
</script>