<template>

  <!--遍历订单-->
    <nut-cell
            v-for="order in order_data"
            :key="order.label"
            :desc="order.pay_time" :title='下单时间'
            @click="reqOrderDetail(order.pay_month,order.tracking_number,order.pay_time,order.status,order.address,order.phone,order.pay_id)">
        <template #icon>
            <icon-paypal v-if="order.status!==0" fill="#333" size="14" theme="outline"/>
            <icon-paypal v-if="order.status===0" fill="#FFEA66" size="14" theme="outline"/>
        </template>
    </nut-cell>

    <nut-cell v-if="!orderShowDisplay">
        未查找到订购信息
    </nut-cell>
    <nut-popup v-model:visible="orderDetailPopupDisplay" :style="{ height: '60%',width:'80%' }" :teleport-disable="true"
               round>
        <nut-cell :desc="pay_month" title="服务月数">
            <template #icon>
                <icon-id-card fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="tracking_number" title="快递单号">
            <template #icon>
                <icon-ambulance fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="pay_time" title="下单时间">
            <template #icon>
                <icon-hourglass-null fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="order_status" title="订单状态">
            <template #icon>
                <icon-id-card fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="order_address" title="订单地址">
            <template #icon>
                <icon-home fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="order_phone" title="订单手机号">
            <template #icon>
                <icon-phone fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-button v-if="this.order_status!=='未发货'&&this.order_status!=='未订购'" color="#1368D7"
                    size="small" style="margin-left: 20px" @click="reqLogisticInfo">查询快递信息
        </nut-button>
        <nut-button v-if="this.order_status==='已发货'" color="#1368D7" size="small" style="margin-left: 20px"
                    @click="gotDevice(this.pay_id)">已收到货物
        </nut-button>
    </nut-popup>
    <nut-popup v-model:visible="logisticPopupDisplay" :style="{ height: '60%',width:'80%' }" :teleport-disable="true"
               round>

        <nut-cell-group :title="tracking_number">
            <nut-cell :desc="logistic_expPhone" :title="logistic_expName">
                <template v-slot:icon>
                    <nut-image :src="logistic_logo" style="width:40px;height: 40px;"></nut-image>
                </template>
            </nut-cell>
            <nut-cell :desc="logistic_takeTime" :title="logistic_deliverystatus">
            </nut-cell>
            <nut-cell
                    v-for="logistic in logistic_list"
                    :key="logistic.label"
                    :desc="logistic.time" :title="logistic.status">
                <template #icon>
                    <icon-dot fill="#333" size="10" theme="outline"/>
                </template>
            </nut-cell>
        </nut-cell-group>
    </nut-popup>
</template>

<script>
import axios from "axios";

export default {
    name: "OrderDetail",
    data() {
        return {


            pay_id: null,
            pay_time: null,
            order_id: null,
            tracking_number: null,
            order_status: null,
            order_address: null,
            order_phone: null,
            orderDetailPopupDisplay: false,
            logisticPopupDisplay: false,
            logistic_deliverystatus: null,
            logistic_expName: null,
            logistic_expSite: null,
            logistic_expPhone: null,
            logistic_updateTime: null,
            logistic_takeTime: null,
            logistic_logo: null,
            orderShowDisplay: true,
            logistic_list: [{
                time: null,
                status: null,
            }],
            order_data: [
                {
                    order_address: null,
                    order_id: null,
                    pay_id: null,
                    pay_month: null,
                    pay_time: null,
                    tracking_number: null,
                    order_phone: null,
                    order_status: null,
                }
            ],
        }


    },
    methods: {
        reqOrderDetail(pay_month, tracking_number, pay_time, order_status, order_address, order_phone, pay_id) {

            this.LogisticSearch(pay_month, tracking_number, pay_time, order_status, order_address, order_phone, pay_id)
        },
        reqLogisticInfo() {
            this.orderDetailPopupDisplay = false
            this.logisticPopupDisplay = true
        },
        LogisticSearch(pay_month, tracking_number, pay_time, order_status, order_address, order_phone, pay_id) {
            axios({
                method: 'get',
                url: 'https://8.130.14.173/logistic/findLogistic',
                params: {
                    'no': tracking_number,
                }
            })
                .then((response) => {
                    this.pay_month = pay_month
                    this.tracking_number = tracking_number
                    this.pay_time = pay_time
                    const orderStatusMap = {
                        boolean: {
                            0: '未订购',
                            1: '未发货',
                            2: '已发货',
                            3: '已收货',
                            4: '订单异常'
                        }
                    }
                    this.order_status = orderStatusMap.boolean[order_status]
                    this.order_address = order_address
                    this.order_phone = order_phone
                    this.pay_id = pay_id

                    this.$store.commit('setLogisticList', response.data.list)
                    this.$store.commit('setLogisticLogo', response.data.logo)
                    this.$store.commit('setLogisticExpSite', response.data.expSite)
                    this.$store.commit('setLogisticExpName', response.data.expName)
                    this.$store.commit('setLogisticExpPhone', response.data.expPhone)
                    this.$store.commit('setLogisticDeliveryStatus', response.data.deliverystatus)
                    this.$store.commit('setLogisticUpdateTime', response.data.updateTime)
                    this.$store.commit('setLogisticTakeTime', response.data.takeTime)
                    this.logistic_list = this.$store.state.logistic_list
                    this.logistic_logo = this.$store.state.logistic_logo
                    const deliveryStatusMap = {
                        boolean: {
                            0: '快递收件（揽件）',
                            1: '在途中',
                            2: '正在派件',
                            3: '已签收',
                            4: '派送失败',
                            5: '疑难件',
                            6: '退件签收'
                        }
                    }
                    this.logistic_deliverystatus = deliveryStatusMap.boolean[this.$store.state.logistic_deliverystatus]
                    this.logistic_expName = this.$store.state.logistic_expName
                    this.logistic_expPhone = this.$store.state.logistic_expPhone
                    this.logistic_expSite = this.$store.state.logistic_expSite
                    this.logistic_updateTime = this.$store.state.logistic_updateTime
                    this.logistic_takeTime = this.$store.state.logistic_take_time
                    console.log(response)
                    this.orderDetailPopupDisplay = true
                })
                .catch((error) => {
                    if (this.order_status === "未订购") {
                        this.orderShowDisplay = false
                    }
                    alert("未查询到快递信息，可能未及时发货，相关信息请联系平台相关人员")
                    console.log('未查询到结果')

                });
        },
        gotDevice(pay_id) {
            axios({
                method: 'post',
                url: 'https://8.130.14.173/order/ensureGet',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'pay_id': pay_id,
                }

            })
                .then((response) => {
                    console.log(response)
                    location.reload();
                })
                .catch((error) => {
                    console.log('更改状态失败')
                    console.log(error)
                });

        }
    },
    mounted() {
        this.order_data = this.$store.state.order_data
        /*        this.order_id=this.$store.state.order_id
                const orderStatusMap = {boolean: {0: '未订购', 1: '未发货', 2: '已发货', 3: '已收货', 4: '订单异常'}}
                this.order_status = orderStatusMap.boolean[this.$store.state.order_status]
                this.order_address=this.$store.state.order_address
                this.order_phone=this.$store.state.order_phone
                this.pay_time=this.$store.state.pay_time
                this.tracking_number=this.$store.state.tracking_number
                this.pay_id=this.$store.state.pay_id*/


    },
    /*    beforeMount() {

            const unwatchContactToken = this.$watch('$store.state.user_token', function (n) {
                if (n) {
                    unwatchContactToken();
                }
            });
        },*/
}
</script>

<style scoped>

</style>