<template>
    <nut-tabs v-model="tabState.value" :title-scroll="true" style="width: 100%" type="smile">
        <nut-tab-pane title="重力">
            <AccG/>
        </nut-tab-pane>
        <nut-tab-pane title="前向">
            <AccGZ/>
        </nut-tab-pane>
        <nut-tab-pane title="侧向">
            <AccGX/>
        </nut-tab-pane>
        <nut-tab-pane title="垂直">
            <AccGY/>
        </nut-tab-pane>
    </nut-tabs>
</template>

<script>
import {reactive} from "vue";
import AccG from "@/components/vehicle/tab/sensor/g/AccG.vue";
import AccGZ from "@/components/vehicle/tab/sensor/g/AccGZ.vue";
import AccGX from "@/components/vehicle/tab/sensor/g/AccGX.vue";
import AccGY from "@/components/vehicle/tab/sensor/g/AccGY.vue";

export default {
    name: "G",
    components: {AccGY, AccGX, AccGZ, AccG},
    setup() {
        const tabState = reactive({
            value: '0',
        });
        return {tabState};
    },
}
</script>