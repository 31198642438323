<template>
    <nut-popup v-model:visible="visible" :close-on-click-overlay="false" :style="{ padding: '30px 50px' }" round>
        <nut-indicator :block="true" :current="1" :size="2" algin="center">设备激活状态</nut-indicator>
        <div>
            <nut-cell>
                <nut-empty description="设备激活状态">
                    <template #image>
                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <title>icon/送播送带</title>
                            <g id="icon/送播送带" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <g id="分组-2" fill-rule="nonzero">
                                    <g id="分组" transform="translate(6.000000, 23.000000)">
                                        <path
                                                id="合并形状"
                                                d="M6,-3.55271368e-15 C6,6.627417 11.372583,12 18,12 L18,12 L18,18 L18,18 C8.0588745,18 0,9.9411255 0,-3.55271368e-15 Z"
                                                fill="#62A6F5"></path>
                                        <path
                                                id="三角形-copy"
                                                d="M25.3516583,9.88394476 L31.5620939,19.9759026 C31.8515449,20.4462605 31.7048909,21.0622073 31.234533,21.3516583 C31.0769253,21.4486477 30.8954954,21.5 30.7104356,21.5 L18.2895644,21.5 C17.7372797,21.5 17.2895644,21.0522847 17.2895644,20.5 C17.2895644,20.3149402 17.3409168,20.1335103 17.4379061,19.9759026 L23.6483417,9.88394476 C23.9377927,9.41358686 24.5537395,9.26693286 25.0240974,9.55638387 C25.1574179,9.63842722 25.269615,9.75062432 25.3516583,9.88394476 Z"
                                                fill="#2975F9"
                                                transform="translate(24.500000, 15.000000) rotate(-270.000000) translate(-24.500000, -15.000000) "></path>
                                    </g>
                                    <g id="分组-copy"
                                       transform="translate(26.500000, 13.500000) rotate(-180.000000) translate(-26.500000, -13.500000) translate(11.000000, 2.000000)">
                                        <path
                                                id="合并形状"
                                                d="M6,-3.55271368e-15 C6,6.627417 11.372583,12 18,12 L18,12 L18,18 L18,18 C8.0588745,18 0,9.9411255 0,-3.55271368e-15 Z"
                                                fill="#62A6F5"></path>
                                        <path
                                                id="三角形-copy"
                                                d="M25.3516583,9.88394476 L31.5620939,19.9759026 C31.8515449,20.4462605 31.7048909,21.0622073 31.234533,21.3516583 C31.0769253,21.4486477 30.8954954,21.5 30.7104356,21.5 L18.2895644,21.5 C17.7372797,21.5 17.2895644,21.0522847 17.2895644,20.5 C17.2895644,20.3149402 17.3409168,20.1335103 17.4379061,19.9759026 L23.6483417,9.88394476 C23.9377927,9.41358686 24.5537395,9.26693286 25.0240974,9.55638387 C25.1574179,9.63842722 25.269615,9.75062432 25.3516583,9.88394476 Z"
                                                fill="#2975F9"
                                                transform="translate(24.500000, 15.000000) rotate(-270.000000) translate(-24.500000, -15.000000) "></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </template>
                    <div style="margin-top: 10px">
                        <nut-animate :loop='true' type='flicker'>
                            <nut-button size="small" type="info">
                                <icon-loading-one fill="#ffffff" size="14" theme="outline"/>&nbsp;验证中
                            </nut-button>
                        </nut-animate>
                    </div>
                </nut-empty>
            </nut-cell>
        </div>
    </nut-popup>
</template>

<script>

export default {
    name: "InActivation",
    data() {
        return {
            visible: true
        }
    },
    mounted() {
        const unwatchActiveState = this.$watch('$store.state.active_state', function (n) {
            if (n !== null) {
                this.visible = false;
                unwatchActiveState();
            }
        });
    }
}
</script>