<template>
    <nut-popup v-model:visible="visible" :close-on-click-overlay="false" :style="{ padding: '30px 50px' }" round>
        <nut-dialog v-model:visible="sensorAlertVisible" :no-cancel-btn="true"
                    content="行驶开始时请将设备垂直于车辆行驶方向（即正对驾驶员方向）放置。"
                    title="传感方向校准"></nut-dialog>
        <nut-dialog v-model:visible="volumeAlertVisible" :no-cancel-btn="true"
                    content="请将音量调整至最大以确保预警信息及警报音能够清晰播放。"
                    title="音量调整"></nut-dialog>
        <div>
            <nut-cell>
                <nut-steps :current="currentCheckStep" direction="vertical"
                           style="width: 180px; --nut-steps-base-title-margin-bottom: 0px;">
                    <nut-step title="内核兼容性">1</nut-step>
                    <nut-step title="网络状态">2</nut-step>
                    <nut-step title="IP地址校验">3</nut-step>
                    <nut-step content="*请轻微晃动设备" title="传感器检测">4</nut-step>
                    <nut-step title="传感方向校准">5</nut-step>
                    <nut-step content="*测试过程包含重力校准，同时可能受到环境因素影响，需较长时间，请耐心等待"
                              title="定位测试">6
                    </nut-step>
                    <nut-step content="*若初次授权提示失败，请刷新页面重新进行系统自检" title="音频权限">7</nut-step>
                    <nut-step title="资源加载">8</nut-step>
                    <nut-step title="行程注册">9</nut-step>
                    <nut-step title="服务远端初始化">10</nut-step>
                </nut-steps>
            </nut-cell>
            <div v-if="state === null" style="text-align: center; margin-top: -25px">
                <nut-animate :loop='true' type='flicker'>
                    <nut-button size="small" type="info">
                        <icon-loading-one fill="#ffffff" size="14" theme="outline"/>&nbsp;自检中
                    </nut-button>
                </nut-animate>
            </div>
            <div v-if="state === true" style="text-align: center; margin-top: -25px">
                <nut-button plain size="mini" type="success">
                    <icon-check-one fill="#0ed57d" size="12" theme="outline"/>&nbsp;成功
                </nut-button>
                <nut-divider/>
                <nut-animate :loop='true' type='flicker'>
                    <nut-button size="small" type="info" @click="login">
                        <icon-login fill="#ffffff" size="14" theme="outline"/>
                        进入系统
                    </nut-button>
                </nut-animate>
            </div>
            <div v-if="state === false" style="text-align: center; margin-top: -25px">
                <nut-button plain size="mini" type="danger">
                    <icon-caution fill="#f52f3e" size="12" theme="outline"/>&nbsp;失败：{{ checkResult }}
                </nut-button>
                <nut-divider/>
                <nut-animate :loop='true' type='flicker'>
                    <nut-button onclick="window.location.reload();" size="small" type="success">
                        <icon-refresh fill="#ffffff" size="14" theme="outline"/>&nbsp;刷新
                    </nut-button>
                </nut-animate>
            </div>
        </div>
    </nut-popup>
</template>

<script>

import axios from "axios";
import Recorder from "js-audio-recorder";
import {nanoid} from "nanoid";

export default {
    name: "SystemCheck",
    data() {
        return {
            visible: false,
            state: null,
            currentCheckStep: 0,
            checkResult: '',
            motionResult: false,
            sensorAlertVisible: false,
            volumeAlertVisible: false,
            askAudio: new Audio(require('@/assets/audio/alertAsk.wav')),
            startAudio: new Audio(require('@/assets/audio/alertStart.wav')),
            finAudio: new Audio(require('@/assets/audio/alertFin.wav')),
        }
    },
    methods: {
        init: function () {
            // 内核兼容性检测
            new Promise((resolve, reject) => {
                this.currentCheckStep = 1;
                if (navigator.connection === undefined) {
                    this.$store.commit('setCheckState', '带宽状态获取异常');
                    reject();
                } else if (navigator.geolocation === undefined) {
                    this.$store.commit('setCheckState', '地理信息获取异常');
                    reject();
                } else {
                    resolve();
                }
            })
                // 网络状态
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 2;
                        if (navigator.onLine === undefined) {
                            this.$store.commit('setCheckState', '网络连接信息获取失败');
                            reject();
                        } else if (!navigator.onLine) {
                            this.$store.commit('setCheckState', '网络连接异常');
                            reject();
                        } else {
                            resolve();
                        }
                    })
                })
                // IP地址校验
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 3;
                        axios({
                            method: 'get',
                            url: 'https://8.130.14.173/vehicle/ip',
                            headers: {
                                authorization: this.$store.state.token
                            }
                        })
                            .then((response) => {
                                if (/^中国/g.test(response.data)) {
                                    resolve();
                                } else {
                                    this.$store.commit('setCheckState', '仅在境内提供服务');
                                    reject();
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                this.$store.commit('setCheckState', error.response.data.msg);
                                reject();
                            });
                    })
                })
                // 传感器检测
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 4;

                        let mf = {
                            a: false,
                            r: false,
                            i: false,
                        }

                        let of = false;

                        function motionTest(e) {
                            if (e.acceleration.x !== 0 && e.acceleration.y !== 0 && e.acceleration.z !== 0) {
                                mf.a = true;
                            }
                            if (e.rotationRate.gamma !== 0 && e.rotationRate.beta !== 0 && e.rotationRate.alpha !== 0) {
                                mf.r = true;
                            }
                            if (e.interval !== 0) {
                                mf.i = true;
                            }
                        }

                        function orientationTest(e) {
                            if (e.alpha !== 0 && e.beta !== 0 && e.gamma !== 0) {
                                of = true;
                            }
                        }

                        addEventListener('devicemotion', motionTest);
                        addEventListener('deviceorientation', orientationTest);

                        const si = setInterval(() => {
                            if (mf.a && mf.r && mf.i && of) {
                                removeEventListener('devicemotion', motionTest);
                                removeEventListener('deviceorientation', orientationTest);
                                clearInterval(si);
                                resolve();
                            }
                        }, 200);

                        setTimeout(() => {
                            if (!(mf.a && mf.r && mf.i)) {
                                this.$store.commit('setCheckState', '传感器不存在或异常');
                                clearInterval(si);
                                reject();
                            }
                        }, 3000);
                    })
                })
                // 传感方向校准
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 5;
                        this.sensorAlertVisible = true;

                        const si = setInterval(() => {
                            if (this.sensorAlertVisible === false) {
                                clearInterval(si);
                                resolve();
                            }
                        }, 200);
                    })
                })
                // 定位测试
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 6;

                        let g = null;

                        navigator.geolocation.getCurrentPosition((position) => {
                            g = 9.780327 * (1 + 0.0053024 * Math.pow(Math.sin(position.coords.latitude), 2) - 0.0000058 * Math.pow(Math.sin(2 * position.coords.latitude), 2));
                        })

                        const si = setInterval(() => {
                            if (g !== null) {
                                this.$store.commit('setAccG', g.toFixed(5));
                                clearInterval(si);
                                resolve();
                            }
                        }, 200);

                        setTimeout(() => {
                            if (g === null) {
                                this.$store.commit('setCheckState', '定位功能异常，请移步室外或刷新重试');
                                clearInterval(si);
                                reject();
                            }
                        }, 60000);
                    })
                })
                // 音频权限
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 7;
                        let permission = false;
                        Recorder.getPermission().then(() => {
                            permission = true;
                            resolve();
                        }, (error) => {
                            console.log(`${error.name} : ${error.message}`);
                        });

                        setTimeout(() => {
                            if (!permission) {
                                this.$store.state.check_state = '录音权限获取失败';
                                reject();
                            }
                        }, 1000);
                    })
                })
                // 资源加载
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 8;
                        this.volumeAlertVisible = true;
                        const vsi = setInterval(() => {
                            if (this.volumeAlertVisible === false) {
                                clearInterval(vsi);
                                let state = false;
                                const si = setInterval(() => {
                                    if (this.askAudio.readyState === 4 && this.startAudio.readyState === 4 && this.finAudio.readyState === 4 && this.volumeAlertVisible === false) {
                                        state = true;
                                        clearInterval(si);
                                        resolve();
                                    }
                                }, 200)

                                setTimeout(() => {
                                    if (!state) {
                                        this.$store.state.check_state = '资源加载异常或超时';
                                        clearInterval(si);
                                        reject();
                                    }
                                }, 6000);
                            }
                        }, 200);
                    })
                })
                // 行程注册
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 9;
                        const did = this.$store.state.device_uuid;
                        const nid = nanoid(32);
                        const iid = did.slice(0, 4) + nid.slice(0, 4)
                            + did.slice(4, 8) + nid.slice(4, 8)
                            + did.slice(8, 12) + nid.slice(8, 12)
                            + did.slice(12, 16) + nid.slice(12, 16)
                            + did.slice(16, 20) + nid.slice(16, 20)
                            + did.slice(20, 24) + nid.slice(20, 24)
                            + did.slice(24, 28) + nid.slice(24, 28)
                            + did.slice(28, 32) + nid.slice(28, 32);
                        this.$store.commit('setItineraryUUID', iid);
                        axios({
                            method: 'post',
                            url: 'https://8.130.14.173/vehicle/itinerary/reg',
                            headers: {
                                authorization: this.$store.state.token
                            },
                            params: {
                                'id': iid,
                                'user': this.$store.state.user
                            }
                        })
                            .then((response) => {
                                resolve();
                            })
                            .catch((error) => {
                                this.$store.commit('setCheckState', error.response.data.msg);
                                reject();
                            });
                    })
                })
                // 服务远端初始化
                .then(() => {
                    return new Promise((resolve, reject) => {
                        this.currentCheckStep = 10;
                        this.$store.commit('setMqState', false);
                        let mqConnected = false;
                        const unwatchMqState = this.$watch('$store.state.mq_state', function (n) {
                            if (n === true) {
                                unwatchMqState();
                                mqConnected = true;
                                resolve();
                            }
                        });
                        setTimeout(() => {
                            if (!mqConnected) {
                                unwatchMqState();
                                reject();
                            }
                        }, 5000);
                    })
                })
                // 自检通过
                .then(() => {
                    this.currentCheckStep = 11;
                    this.state = true;
                })
        },
        login: function () {
            this.$store.commit('setCheckState', true);
            this.$store.commit('setMonitorState', true);
        }
    },
    mounted() {
        const unwatchCheckState = this.$watch('$store.state.check_state', function (n) {
            if (n === false) {
                this.visible = true;
                this.init();
            } else if (n === true) {
                this.visible = false;
                unwatchCheckState();
            } else {
                this.checkResult = n;
                this.state = false;
                unwatchCheckState();
            }
        });
    }
}
</script>