<template>
    <div/>
</template>

<script>
import Cookies from 'js-cookie'

export default {
    name: "Ws",
    data() {
        return {
            token: Cookies.get('admin_token'),
            client: null
        }
    },
    methods: {
        connect() {
            if (typeof WebSocket === 'undefined') {
                console.log("WebSocet Err 浏览器支持");
            } else {
                if (this.client !== null) {
                    this.client.close();
                }
                this.client = new WebSocket('wss://8.130.14.173/ws?token=' + this.token);
                this.client.onopen = this.open;
                this.client.onerror = this.error;
                this.client.onmessage = this.getMessage;
                this.client.onclose = this.close;
            }
        },
        open() {
            this.$store.commit('setSrcState', true);
        },
        error() {
            console.log('WebSocket Err')
            this.$store.commit('setSrcState', false);
        },
        getMessage(msg) {
            const data = JSON.parse(msg.data);
            switch (data.type) {
                case 'client-warning-suspect':
                    this.$store.commit('setSrcWarningLatest', data);
                    break;
                case 'client-danger-determine-action':
                    this.$store.commit('setSrcDeleteFlag',
                        {
                            id: data.info.itinerary_uuid,
                            ts: data.info.ref_timestamp
                        }
                    );
                    break;
                case 'client-warning-cancel-action':
                    this.$store.commit('setSrcDeleteFlag',
                        {
                            id: data.info.itinerary_uuid,
                            ts: data.info.ref_timestamp
                        }
                    );
                    break;
                case 'client-danger-cancel-action':
                    this.$store.commit('setSrcDeleteFlag',
                        {
                            id: data.info.itinerary_uuid,
                            ts: data.info.ref_timestamp
                        }
                    );
                    break;
            }
            console.log(msg.data);
        },
        send() {
            this.client.send("这是传送数据");
        },
        close() {
            this.$store.commit('setSrcState', false);
        }
    },
    mounted() {
        this.connect();
        const unwatchSrcPauseState = this.$watch('$store.state.src_pause_state', function (n) {
            n ? this.client.close() : this.connect()
        });
    },
}
</script>