<template>
    <nut-cell>
        <nut-tabs v-model="tabState.value" :title-scroll="true" style="width: 100%" type="smile">
            <nut-tab-pane title="X轴">
                <RotX/>
            </nut-tab-pane>
            <nut-tab-pane title="Y轴">
                <RotY/>
            </nut-tab-pane>
            <nut-tab-pane title="Z轴">
                <RotZ/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import RotX from "@/components/vehicle/tab/sensor/rotation/RotX.vue";
import RotY from "@/components/vehicle/tab/sensor/rotation/RotY.vue";
import RotZ from "@/components/vehicle/tab/sensor/rotation/RotZ.vue";

export default {
    name: "Rotation",
    components: {RotZ, RotY, RotX},
    setup() {
        const tabState = reactive({
            value: '0',
        });
        return {tabState};
    },
}
</script>