<template>
    <nut-cell>
        <nut-button @click="this.$store.commit('setAlertState', 1);">测试预警</nut-button>
    </nut-cell>
    <nut-cell v-if="visible">
        <nut-cell :sub-title="normalTs" style="font-size: 20px" title="常规监测 事故概率">
            <template v-slot:link>
                <nut-tag :type="normalPrType" style="font-size: 22px">
                    <icon-check-one v-if="normalPr<33.33" fill="#fff" size="22" theme="outline"/>
                    <icon-help v-if="normalPr>33.33 && normalPr<66.66" fill="#fff" size="22" theme="outline"/>
                    <icon-attention v-if="!(normalPr<66.66)" fill="#fff" size="22" theme="outline"/>
                    &nbsp;{{ normalPr }}%
                </nut-tag>
            </template>
        </nut-cell>
    </nut-cell>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "Status",
    data() {
        return {
            visible: true,
            normalPr: 0,
            normalTs: null,
            normalPrType: 'primary'
        }
    },
    mounted() {
        const unwatchStatusTab = this.$watch('$store.state.status_tab', function (n) {
            this.visible = !!n;
        });
        const unwatchNormalTs = this.$watch('$store.state.normal_ts', (n) => {
            if (n !== null) {
                this.normalTs = moment(n).format("YYYY-MM-DD HH:mm:ss.SSS");
            }
        });
        const unwatchNormalPr = this.$watch('$store.state.normal_pr', (n) => {
            if (n !== null) {
                this.normalPr = n.toFixed(2);
                if (n < 33.33) {
                    this.normalPrType = 'success';
                } else if (n < 66.66) {
                    this.normalPrType = 'warning';
                } else {
                    this.normalPrType = 'danger';
                }
            }
        });
    }
}
</script>