<template>
    <nut-popup v-model:visible="visible" :close-on-click-overlay="false" :style="{ height: '36%' }" position="top">
        <nut-popup v-model:visible="callPopupVisible" :style="{ height: '24%' }" position="bottom" round>
            <nut-cell-group>
                <nut-cell desc="交通事故报警电话" style="font-size: 200%; font-weight: bold" title="122"
                          @click="call('122')"></nut-cell>
                <nut-cell desc="中国大陆医疗急救电话号码" style="font-size: 200%; font-weight: bold" title="120"
                          @click="call('120')"></nut-cell>
                <nut-cell desc="公安报警电话" style="font-size: 200%; font-weight: bold" title="110"
                          @click="call('110')"></nut-cell>
            </nut-cell-group>
        </nut-popup>
        <h2 style="text-align: center">
            <icon-red-cross fill="#FA2C19" size="22" theme="outline"/>&nbsp;请等待救援人员提供帮助
        </h2>
        <br>
        <div style="text-align: center">
            <nut-button shape="square" style="height: 100px; width: 100px; font-size: 30px"
                        type="danger"
                        @click="callPopupVisible = true">救援电话
            </nut-button>&nbsp;
            <nut-button shape="square" style="height: 100px; width: 100px; font-size: 30px"
                        type="primary"
                        @click="this.$store.commit('setAlertState', 0)">警报取消
            </nut-button>
        </div>
    </nut-popup>
</template>

<script>
export default {
    name: "Alarm",
    data() {
        return {
            visible: false,
            callPopupVisible: false
        }
    },
    methods: {
        call(number) {
            window.location.href = 'tel://' + number;
        }
    },
    mounted() {
        const unwatchAlertState = this.$watch('$store.state.alert_state', (n) => {
            this.visible = n === 3;
        })
    }
}
</script>