<template>
    <nut-popup v-model:visible="visible" :close-on-click-overlay="false" :style="{ padding: '30px 50px' }" round>
        <nut-indicator :block="true" :current="2" :size="2" algin="center">设备激活状态</nut-indicator>
        <div>
            <nut-cell>
                <nut-empty description="设备激活状态">
                    <template #image>
                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>icon/归还确认</title>
                            <defs>
                                <circle id="path-1" cx="37" cy="37" r="9"></circle>
                                <filter id="filter-2" filterUnits="objectBoundingBox" height="172.2%" width="172.2%"
                                        x="-36.1%"
                                        y="-30.6%">
                                    <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1"
                                                    stdDeviation="2"></feGaussianBlur>
                                    <feColorMatrix in="shadowBlurOuter1"
                                                   type="matrix"
                                                   values="0 0 0 0 0   0 0 0 0 0.239215686   0 0 0 0 0.149019608  0 0 0 0.2 0"></feColorMatrix>
                                </filter>
                            </defs>
                            <g id="icon/归还确认" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <path
                                        id="合并形状"
                                        d="M8.12599558,5.0000851 C8.04374524,5.3196794 8,5.65473108 8,6 C8,8.14219539 9.68396847,9.89107888 11.8003597,9.99510469 L12,10 L27,10 C29.209139,10 31,8.209139 31,6 C31,5.65473108 30.9562548,5.3196794 30.8740044,5.0000851 L35,5 C36.1045695,5 37,5.8954305 37,7 L37,43 C37,44.1045695 36.1045695,45 35,45 L4,45 C2.8954305,45 2,44.1045695 2,43 L2,7 C2,5.8954305 2.8954305,5 4,5 L8.12599558,5.0000851 Z M26.4907962,2 C27.3166973,2 27.6509198,2.09282561 27.9768457,2.2671327 C28.3027716,2.4414398 28.5585602,2.69722837 28.7328673,3.0231543 C28.9071744,3.34908022 29,3.68330273 29,4.50920383 L29,5.49079617 C29,6.31669727 28.9071744,6.65091978 28.7328673,6.9768457 C28.5585602,7.30277163 28.3027716,7.5585602 27.9768457,7.7328673 C27.6509198,7.90717439 27.3166973,8 26.4907962,8 L12.5092038,8 C11.6833027,8 11.3490802,7.90717439 11.0231543,7.7328673 C10.6972284,7.5585602 10.4414398,7.30277163 10.2671327,6.9768457 C10.0928256,6.65091978 10,6.31669727 10,5.49079617 L10,4.50920383 C10,3.68330273 10.0928256,3.34908022 10.2671327,3.0231543 C10.4414398,2.69722837 10.6972284,2.4414398 11.0231543,2.2671327 C11.3490802,2.09282561 11.6833027,2 12.5092038,2 L26.4907962,2 Z"
                                        fill="#37B370" fill-rule="nonzero"></path>
                                <path
                                        id="矩形"
                                        d="M8.5,18 L30.5,18 C31.3284271,18 32,18.6715729 32,19.5 C32,20.3284271 31.3284271,21 30.5,21 L8.5,21 C7.67157288,21 7,20.3284271 7,19.5 C7,18.6715729 7.67157288,18 8.5,18 Z"
                                        fill="#FFFFFF" fill-rule="nonzero"></path>
                                <path
                                        id="矩形-copy-2"
                                        d="M8.5,26 L18.5,26 C19.3284271,26 20,26.6715729 20,27.5 C20,28.3284271 19.3284271,29 18.5,29 L8.5,29 C7.67157288,29 7,28.3284271 7,27.5 C7,26.6715729 7.67157288,26 8.5,26 Z"
                                        fill="#FFFFFF" fill-rule="nonzero"></path>
                                <g id="椭圆形" fill-rule="nonzero">
                                    <use fill="black" fill-opacity="1" filter="url(#filter-2)"
                                         xlink:href="#path-1"></use>
                                    <use fill="#006F45" xlink:href="#path-1"></use>
                                </g>
                                <path
                                        id="合并形状"
                                        d="M33.6568542,32.6568542 C34.209139,32.6568542 34.6568542,33.1045695 34.6568542,33.6568542 L34.6568542,36.6558542 L41.6568542,36.6568542 C42.209139,36.6568542 42.6568542,37.1045695 42.6568542,37.6568542 C42.6568542,38.209139 42.209139,38.6568542 41.6568542,38.6568542 L33.6741634,38.6567075 C33.6684054,38.6568052 33.6626356,38.6568542 33.6568542,38.6568542 C33.1045695,38.6568542 32.6568542,38.209139 32.6568542,37.6568542 L32.6568542,33.6568542 C32.6568542,33.1045695 33.1045695,32.6568542 33.6568542,32.6568542 Z"
                                        fill="#FFFFFF" fill-rule="nonzero"
                                        transform="translate(37.656854, 35.656854) rotate(-45.000000) translate(-37.656854, -35.656854) "></path>
                            </g>
                        </svg>
                    </template>
                    <div style="margin-top: 10px; text-align: center">
                        <nut-button plain size="mini" type="success">
                            <icon-check-one fill="#0ed57d" size="12" theme="outline"/>&nbsp;已激活
                        </nut-button>
                        <nut-divider/>
                        <nut-animate :loop='true' type='flicker'>
                            <nut-button size="small" type="info" @click="check">
                                <icon-checklist fill="#ffffff" size="14" theme="outline"/>&nbsp;系统自检
                            </nut-button>
                        </nut-animate>
                    </div>
                </nut-empty>
            </nut-cell>
        </div>
    </nut-popup>
</template>

<script>

export default {
    name: "ActivationSuccess",
    data() {
        return {
            visible: false
        }
    },
    methods: {
        check() {
            this.visible = false;
            this.$store.commit('setCheckState', false);
        }
    },
    mounted() {
        const unwatchActiveState = this.$watch('$store.state.active_state', function (n) {
            if (n === true) {
                this.visible = true;
                unwatchActiveState();
            }
        });
    }
}
</script>