<template>
    <nut-cell id="targetPay" title="订购服务" @click="reqPayPopup()">
        <template #icon>
            <icon-iphone fill="#333" size="24" theme="outline"/>
        </template>
    </nut-cell>
    <nut-popup v-model:visible="PayPopupDisplay" :style="{ height: '60%',width:'80%' }" :teleport-disable="true" round>
        <nut-picker
                v-model="pay_month"
                :columns="MonthColumns"
                :v-model:value=null
                option-height="20"
                @cancel="PayPopupDisplay=false"
                @confirm="reqPaypal()"
        >
        </nut-picker>
        <nut-address-list
                :data="order_data"
                :data-options="dataMapOptions"
                :show-bottom-button="false"
                @click-item="clickItem"
                @edit-icon="editClick"
        >
        </nut-address-list>

    </nut-popup>
    <nut-overlay v-model:visible="PayConfirmDisplay" :close-on-click-overlay="false" :teleport-disable="true">
        <div class="wrapper">
            <div class="content" @click="PayDone()">已支付</div>
            <div class="content2" @click="PayAgain">返回支付</div>
            <div class="content3" @click.stop="PayConfirmDisplay = false;">关闭交易</div>
        </div>
    </nut-overlay>


</template>

<script>
import axios from "axios";

export default {
    name: "Pay",
    data() {
        return {
            PayPopupDisplay: false,
            PayConfirmDisplay: false,
            pay_month: null,
            pay_url: null,
            MonthColumns: [
                {text: '3个月', value: '3'},
                {text: '6个月', value: '6'},
                {text: '9个月', value: '9'},
                {text: '12个月', value: '12'},
            ],
            order_data: [
                {
                    addressName: "123",
                    phone: null,
                    defaultAddress: true,
                    fullAddress: null,
                }
            ],
            dataMapOptions: [
                {
                    addressDetail: 'testaddressDetail',
                    addressName: 'addressName'
                }
            ],

        }

    },
    methods: {
        reqPayPopup() {
            this.PayPopupDisplay = true
        },
        reqPaypal() {
            this.PayPopupDisplay = false
            this.PayConfirmDisplay = true
            axios({
                method: 'post',
                url: 'https://8.130.14.173/order/reqPay',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'month': String(this.pay_month),
                    'phone': this.$store.state.user_phone,
                    'address': this.$store.state.user_address,
                }
            })
                .then((response) => {
                    this.$store.commit('setPayUrl', response.data)
                    this.$store.commit('setPayMonth', parseInt(this.pay_month))
                    this.pay_url = this.$store.state.pay_url
                    window.open(this.pay_url)
                    console.log(response)
                })
                .catch((error) => {
                    console.log('支付失败')
                    console.log(error)
                });
        },
        PayDone() {
            location.reload();
        },
        PayAgain() {
            window.open(this.pay_url)
        }

    },
    mounted() {
        this.order_data[0].addressName = this.$store.state.user_id
        this.order_data[0].phone = this.$store.state.user_phone
        this.order_data[0].fullAddress = this.$store.state.user_address
    }

}
</script>

<style scoped>
.wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .content {
        display: flex;
        width: 300px;
        height: 300px;
        background: #fff;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: red;
    }

    .content2 {
        display: flex;
        width: 300px;
        height: 300px;
        background: #fff;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: red;
    }

    .content3 {
        display: flex;
        width: 300px;
        height: 300px;
        background: #fff;
        border-radius: 8px;
        align-items: center;
        justify-content: center;
        color: red;
    }
}
</style>
