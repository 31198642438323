<template>
    <nut-cell v-if="visible">

        <nut-tabs v-model="tabState.value" :title-scroll="true" size="large" style="width: 100%" type="smile">
            <nut-tab-pane style="padding: 0;" title="服务相关">
                <icon-help fill="#333" size="20" theme="outline" @click="openTourPay"/>
                <Pay/>
                <BindDevice/>
            </nut-tab-pane>
            <nut-tab-pane style="padding: 0;" title="订购信息">
                <OrderDetail/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import OrderDetail from "@/components/user/UserTap/order/OrderDetail.vue";
import axios from "axios";
import Pay from "@/components/user/UserTap/order/Pay.vue";
import BindDevice from "@/components/test/testZxing.vue";

export default {
    name: "Order",
    components: {BindDevice, OrderDetail, Pay},
    data() {
        return {

            visible: false,
            order_data: [
                {
                    id: null,
                    tracking_number: null,
                    status: null,
                    address: null,
                    phone: null,
                }
            ],

        }
    },
    setup() {
        const tabState = reactive({
            value: '0',
        });

        return {tabState};
    },
    methods: {

        getOrderInfo() {

            axios({
                method: 'get',
                url: 'https://8.130.14.173/order/reqOrder',
                headers: {
                    authorization: this.$store.state.user_token,
                },
            })
                .then((response) => {
                    this.$store.commit('setOrderData', response.data.data)
                    console.log(response)
                })
                .catch((error) => {
                    console.log('查询失败')
                    console.log(error)
                });
        },
        openTourPay() {
            this.$emit("openTourPay")
        },


    },
    mounted() {
        const unwatchOrderTab = this.$watch('$store.state.order_tab', function (n) {
            this.visible = !!n;
        });
        this.getOrderInfo()
    },
    beforeMount() {

        const unwatchContactToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                unwatchContactToken();
            }
        });
    },
}
</script>

<style scoped>

</style>