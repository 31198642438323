<template>
    <nut-cell :title="this.$store.state.acc_g.toString()" center>
        <template v-slot:link>
            <span style="color: #8c8c8c">米/平方秒 (m/s<sup>2</sup>)</span>
        </template>
    </nut-cell>
</template>

<script>
export default {
    name: "AccG"
}
</script>