<template>

    <nut-cell id="targetBind" title="绑定设备" @click="scan">
        <template #icon>
            <icon-iphone fill="#333" size="24" theme="outline"/>
        </template>
    </nut-cell>
    <nut-popup v-model:visible="scanPopup" :style="{ height: '60%' }" :teleport-disable="true" position="top">
        <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit"/>
    </nut-popup>
    <nut-popup v-model:visible="BindPopup" :style="{ height: '20%',width:'80%' }" :teleport-disable="true" round>
        <nut-input v-model="scan_uuid" placeholder="设备UUID"/>
        <nut-button color="#1368D7" size="small" style="margin-left: 110px;margin-top: 40px" @click="BindDevice">绑定
        </nut-button>
    </nut-popup>


</template>

<script>
import {QrcodeStream} from "vue-qrcode-reader/src";
import axios from "axios";

export default {
    name: "BindDevice",
    components: {
        QrcodeStream
    },
    data() {
        return {
            scanPopup: false,
            camera: 'off',
            BindPopup: false,
            scan_uuid: null,
            pay_day: 0,
            scan_user: null
        }
    },
    methods: {
        scan() {
            this.scanPopup = true;
            this.camera = 'auto';
        },
        async onInit(promise) {
            try {
                await promise
            } catch (e) {
                console.error('QrCode Scan Err, ', e)
            }
        },
        onDecode(content) {
            this.scanPopup = false;
            this.camera = 'off';
            this.scan_uuid = content
            this.BindPopup = true
        },
        BindDevice() {
            this.pay_day = (3) * 30
            axios({
                method: 'post',
                url: 'https://8.130.14.173/order/bind',
                params: {
                    'id': this.scan_uuid,
                    'user': this.scan_user,
                    'time': this.pay_day,
                }
            })
                .then((response) => {
                    console.log(response)
                    location.reload()
                })
                .catch((error) => {
                    console.log('绑定失败')
                    console.log(error)
                });
        },

    },
    mounted() {
        this.order_status = this.$store.state.order_status
        this.scan_user = this.$store.state.user_id
    }
}
</script>