<template>
    <nut-popup v-model:visible="openBlockDisplay" :style="{ width: '40%',height: '100%' }" position="left">
        <div v-if="welcomeDisplay">
            <Welcome/>
        </div>
        <div v-if="loginDisplay">
            <Login/>
        </div>
        <div v-if="registerDisplay">
            <Register/>
        </div>
        <div v-if="updPassDisplay">
            <UpdPassword/>
        </div>
        <div v-if="logoutDisplay">
            <Logout/>
        </div>
    </nut-popup>
    <nut-button color="black" plain type="primary" @click="openBlock">
        <template #icon>
            <My/>
        </template>
    </nut-button>
</template>

<script>
import {My} from '@nutui/icons-vue';
import Register from "@/components/user/register/Register.vue";
import Login from "@/components/user/login/Login.vue";
import Logout from "@/components/user/login/Logout.vue";
import Welcome from "@/components/user/login/Welcome.vue";
import UpdPassword from "@/components/user/UserTap/info/UpdPassword.vue";

export default {
    name: "Block",
    components: {UpdPassword, Welcome, Logout, Login, Register, My},
    data() {
        return {
            openBlockDisplay: false,
            loginDisplay: true,
            registerDisplay: true,
            updPassDisplay: false,
            logoutDisplay: false,
            welcomeDisplay: false,
        }
    },
    methods: {
        openBlock() {
            this.openBlockDisplay = true
        }
    },
    beforeMount() {
        const unwatchUserToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                this.loginDisplay = false;
                this.registerDisplay = false;
                this.updPassDisplay = true;
                this.logoutDisplay = true;
                this.welcomeDisplay = true;
                unwatchUserToken();
            }
        });
    }
}

</script>

<style scoped>

</style>