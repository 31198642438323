<template>
    <div v-show="display">
        <p style="text-align: right; margin-top: -25px; margin-bottom: 0">
            <nut-button size="mini" type="danger" @click="hide">
                <icon-close fill="#FFFFFF" size="18" theme="outline"/>
            </nut-button>
        </p>
        <nut-grid :border="false" :column-num="2">
            <nut-grid-item>
                <nut-button type="danger" @click="determine">确认疑似事故</nut-button>
            </nut-grid-item>
            <nut-grid-item>
                <nut-button type="success" @click="cancel">取消事故预警</nut-button>
            </nut-grid-item>
        </nut-grid>

        <nut-collapse v-model="currentDetail">
            <nut-collapse-item :icon="null" :name="1">
                <template v-slot:title>
                    <icon-sliding-vertical fill="#000000" size="14" theme="outline"/>
                    基本信息
                </template>
                <nut-cell :desc="detailInfo.itinerary_uuid" title="行程ID"></nut-cell>
                <nut-cell :desc="detailInfo.timestamp" title="预警时间"></nut-cell>
                <nut-cell :desc="detailInfo.pr+'%'" title="事故概率"></nut-cell>
            </nut-collapse-item>
            <nut-collapse-item :icon="null" :name="2">
                <template v-slot:title>
                    <icon-sliding-vertical fill="#000000" size="14" theme="outline"/>
                    监测数据
                </template>
                <nut-cell :desc="detailInfo.acc_x==null?'-':detailInfo.acc_x+'m/s²'" title="X轴加速度"></nut-cell>
                <nut-cell :desc="detailInfo.acc_y==null?'-':detailInfo.acc_y+'m/s²'" title="Y轴加速度"></nut-cell>
                <nut-cell :desc="detailInfo.acc_z==null?'-':detailInfo.acc_z+'m/s²'" title="Z轴加速度"></nut-cell>
                <nut-cell :desc="detailInfo.acc_g_x==null?'-':detailInfo.acc_g_x.toString()" title="侧向G值"></nut-cell>
                <nut-cell :desc="detailInfo.acc_g_y==null?'-':detailInfo.acc_g_y.toString()" title="垂直G值"></nut-cell>
                <nut-cell :desc="detailInfo.acc_g_z==null?'-':detailInfo.acc_g_z.toString()" title="正向G值"></nut-cell>
                <nut-cell :desc="detailInfo.ori_alpha==null?'-':detailInfo.ori_alpha+'°'" title="设备朝向"></nut-cell>
                <nut-cell :desc="detailInfo.ori_beta==null?'-':detailInfo.ori_beta+'°'" title="正向角度"></nut-cell>
                <nut-cell :desc="detailInfo.ori_gamma==null?'-':detailInfo.ori_gamma+'°'" title="侧向角度"></nut-cell>
                <nut-cell :desc="detailInfo.rot_x==null?'-':detailInfo.rot_x+'°/s'" title="X轴旋转速率"></nut-cell>
                <nut-cell :desc="detailInfo.rot_y==null?'-':detailInfo.rot_y+'°/s'" title="Y轴旋转速率"></nut-cell>
                <nut-cell :desc="detailInfo.rot_z==null?'-':detailInfo.rot_z+'°/s'" title="Z轴旋转速率"></nut-cell>
                <nut-cell :desc="detailInfo.geo_ts==null?'-':detailInfo.geo_ts" title="地理位置时间戳"></nut-cell>
                <nut-cell :desc="detailInfo.geo_lon==null?'-':detailInfo.geo_lon+'°'" title="经度"></nut-cell>
                <nut-cell :desc="detailInfo.geo_lat==null?'-':detailInfo.geo_lat+'°'" title="纬度"></nut-cell>
                <nut-cell :desc="detailInfo.geo_addr==null?'-':detailInfo.geo_addr" title="定位地址"></nut-cell>
                <nut-cell :desc="detailInfo.geo_acc==null?'-':detailInfo.geo_acc+'m'" title="位置精度"></nut-cell>
                <nut-cell :desc="detailInfo.geo_alt==null?'-':detailInfo.geo_alt+'m'" title="海拔"></nut-cell>
                <nut-cell :desc="detailInfo.geo_heading==null?'-':detailInfo.geo_heading+'°'"
                          title="行驶方向"></nut-cell>
                <nut-cell :desc="detailInfo.geo_speed==null?'-':detailInfo.geo_speed+'km/h'" title="速度"></nut-cell>
                <nut-cell :desc="
detailInfo.weather_code===0?'晴（国内城市白天晴）':
detailInfo.weather_code===1?'晴（国内城市夜晚晴）':
detailInfo.weather_code===2?'晴（国外城市白天晴）':
detailInfo.weather_code===3?'晴（国外城市夜晚晴）':
detailInfo.weather_code===4?'多云':
detailInfo.weather_code===5?'晴间多云':
detailInfo.weather_code===6?'晴间多云':
detailInfo.weather_code===7?'大部多云':
detailInfo.weather_code===8?'大部多云':
detailInfo.weather_code===9?'阴':
detailInfo.weather_code===10?'阵雨':
detailInfo.weather_code===11?'雷阵雨':
detailInfo.weather_code===12?'雷阵雨伴有冰雹':
detailInfo.weather_code===13?'小雨':
detailInfo.weather_code===14?'中雨':
detailInfo.weather_code===15?'大雨':
detailInfo.weather_code===16?'暴雨':
detailInfo.weather_code===17?'大暴雨':
detailInfo.weather_code===18?'特大暴雨':
detailInfo.weather_code===19?'冻雨':
detailInfo.weather_code===20?'雨夹雪':
detailInfo.weather_code===21?'阵雪':
detailInfo.weather_code===22?'小雪':
detailInfo.weather_code===23?'中雪':
detailInfo.weather_code===24?'大雪':
detailInfo.weather_code===25?'暴雪':
detailInfo.weather_code===26?'浮尘':
detailInfo.weather_code===27?'扬沙':
detailInfo.weather_code===28?'沙尘暴':
detailInfo.weather_code===29?'强沙尘暴':
detailInfo.weather_code===30?'雾':
detailInfo.weather_code===31?'霾':
detailInfo.weather_code===32?'风':
detailInfo.weather_code===33?'大风':
detailInfo.weather_code===34?'飓风':
detailInfo.weather_code===35?'热带风暴':
detailInfo.weather_code===36?'龙卷风':
detailInfo.weather_code===37?'冷':
detailInfo.weather_code===38?'热':
detailInfo.weather_code===99?'未知':
        '-'" title="天气"></nut-cell>
                <nut-cell :desc="detailInfo.weather_temp==null?'-':detailInfo.weather_temp+'℃'" title="温度"></nut-cell>
            </nut-collapse-item>

            <nut-collapse-item :icon="null" :name="3">
                <template v-slot:title>
                    <icon-sliding-vertical fill="#000000" size="14" theme="outline"/>
                    行程信息
                </template>
                <nut-cell :desc="detailInfo.itinerary_info.start_time" title="开始时间"></nut-cell>
            </nut-collapse-item>

            <nut-collapse-item :icon="null" :name="4">
                <template v-slot:title>
                    <icon-sliding-vertical fill="#000000" size="14" theme="outline"/>
                    设备信息
                </template>
                <nut-cell :desc="detailInfo.itinerary_info.device_info.id" title="设备ID"></nut-cell>
                <nut-cell :desc="detailInfo.itinerary_info.device_info.active_time" title="服务激活时间"></nut-cell>
                <nut-cell :desc="detailInfo.itinerary_info.device_info.exp_time" title="服务过期时间"></nut-cell>
            </nut-collapse-item>

            <nut-collapse-item :icon="null" :name="5">
                <template v-slot:title>
                    <icon-sliding-vertical fill="#000000" size="14" theme="outline"/>
                    用户信息
                </template>
                <nut-cell :desc="detailInfo.itinerary_info.device_info.user_info.id" title="用户ID"></nut-cell>
                <nut-cell :desc="detailInfo.itinerary_info.device_info.user_info.phone" title="电话号码"></nut-cell>
                <nut-cell :desc="detailInfo.itinerary_info.device_info.user_info.lpn" title="车牌号码"></nut-cell>
                <nut-cell :desc="
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===0?'乘用车':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===1?'摩托车':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===2?'货车':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===3?'三轮汽车':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===4?'拖拉机':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===5?'其他机动车':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===6?'汽车列车':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===7?'低速汽车':
        detailInfo.itinerary_info.device_info.user_info.vehicle_type===8?'其他汽车':
'-'" title="车牌号码"></nut-cell>
                <nut-cell
                        :desc="detailInfo.itinerary_info.device_info.user_info.using_nature==null?'-':detailInfo.itinerary_info.device_info.user_info.using_nature?'生产经营车辆':'非生产经营车辆'"
                        title="使用性质"></nut-cell>
            </nut-collapse-item>

            <nut-collapse-item :icon="null" :name="6">
                <template v-slot:title>
                    <icon-sliding-vertical fill="#000000" size="14" theme="outline"/>
                    联系人信息
                </template>
                <nut-cell v-for="contact in detailInfo.itinerary_info.device_info.user_info.contacts"
                          :key="contact.label"
                          :desc="contact.phone" :title="contact.remark"></nut-cell>
            </nut-collapse-item>

        </nut-collapse>
    </div>

</template>

<script>
import axios from "axios";
import {showNotify} from "@nutui/nutui";
import '@nutui/nutui/dist/packages/notify/style';

export default {
    name: "WarningDetail",
    setup() {
        const primaryNotify = (msg) => {
            showNotify.primary(msg);
        };
        const successNotify = (msg) => {
            showNotify.success(msg);
        };
        const errorNotify = (msg) => {
            showNotify.danger(msg);
        };
        const warningNotify = (msg) => {
            showNotify.warn(msg);
        };
        return {
            primaryNotify,
            successNotify,
            errorNotify,
            warningNotify,
        }
    },
    data() {
        return {
            display: false,
            itinerary_uuid: null,
            timestamp: null,
            ref_timestamp: null,
            currentDetail: [],
            detailInfo: {
                itinerary_uuid: null,
                timestamp: null,
                pr: null,
                acc_x: null,
                acc_y: null,
                acc_z: null,
                acc_g_x: null,
                acc_g_y: null,
                acc_g_z: null,
                ori_alpha: null,
                ori_beta: null,
                ori_gamma: null,
                rot_x: null,
                rot_y: null,
                rot_z: null,
                geo_ts: null,
                geo_lon: null,
                geo_lat: null,
                geo_addr: null,
                geo_acc: null,
                geo_alt: null,
                geo_heading: null,
                geo_speed: null,
                weather_code: null,
                weather_temp: null,
                itinerary_info: {
                    start_time: null,
                    device_info: {
                        id: null,
                        active_time: null,
                        exp_time: null,
                        user_info: {
                            id: null,
                            phone: null,
                            lpn: null,
                            vehicle_type: null,
                            using_nature: null,
                            contacts: [
                                {
                                    remark: null,
                                    phone: null,
                                }
                            ],
                        },
                    },
                },
            }
        }
    },
    methods: {
        detail(info) {
            if (info === null) {
                this.hide();
            } else {
                this.itinerary_uuid = info.itinerary_uuid;
                this.timestamp = info.timestamp;
                this.ref_timestamp = info.ref_timestamp;
                this.getData(this.itinerary_uuid, this.ref_timestamp);
            }
        },
        getData(id, ts) {
            axios({
                method: 'get',
                url: 'https://8.130.14.173/admin/itinerary/detail',
                params: {
                    id: id,
                    ts: ts
                }
            })
                .then((response) => {
                    this.successNotify('详情查询成功！');
                    this.detailInfo = response.data;
                })
                .catch((error) => {
                    this.errorNotify('详情查询失败，原因：' + error.response.data.msg);
                    console.log('Detail Query Err', error.response.data.msg);
                });
        },
        hide() {
            this.$store.commit('setSrcWarningDetail', null);
        },
        determine() {
            axios({
                method: 'patch',
                url: 'https://8.130.14.173/admin/danger/determine',
                params: {
                    id: this.itinerary_uuid,
                    ts: this.ref_timestamp
                }
            })
                .then((response) => {
                    this.successNotify('确认成功，报警指令已下发！');
                    this.$store.commit('setSrcDeleteFlag',
                        {
                            id: this.itinerary_uuid,
                            ts: this.ref_timestamp
                        }
                    );
                    this.hide();
                })
                .catch((error) => {
                    this.errorNotify('确认失败，原因：' + error.response.data.msg);
                    console.log('Danger Determine Err', error.response.data.msg);
                });
        },
        cancel() {
            axios({
                method: 'patch',
                url: `https://8.130.14.173/admin/warning/cancel`,
                params: {
                    id: this.itinerary_uuid,
                    ts: this.ref_timestamp
                }
            })
                .then((response) => {
                    this.successNotify('取消成功，监测状态已恢复！');
                    this.$store.commit('setSrcDeleteFlag',
                        {
                            id: this.itinerary_uuid,
                            ts: this.ref_timestamp
                        }
                    );
                    this.hide();
                })
                .catch((error) => {
                    this.errorNotify('取消失败，原因：' + error.response.data.msg);
                    console.log('Warning Cancel Err', error.response.data.msg);
                });
        },
    },
    mounted() {
        const unwatchSrcWarningDetail = this.$watch('$store.state.src_warning_detail', function (n) {
            this.detailInfo = {
                itinerary_uuid: null,
                timestamp: null,
                pr: null,
                acc_x: null,
                acc_y: null,
                acc_z: null,
                acc_g_x: null,
                acc_g_y: null,
                acc_g_z: null,
                ori_alpha: null,
                ori_beta: null,
                ori_gamma: null,
                rot_x: null,
                rot_y: null,
                rot_z: null,
                geo_ts: null,
                geo_lon: null,
                geo_lat: null,
                geo_addr: null,
                geo_acc: null,
                geo_alt: null,
                geo_heading: null,
                geo_speed: null,
                weather_code: null,
                weather_temp: null,
                itinerary_info: {
                    start_time: null,
                    device_info: {
                        id: null,
                        active_time: null,
                        exp_time: null,
                        user_info: {
                            id: null,
                            phone: null,
                            lpn: null,
                            vehicle_type: null,
                            using_nature: null,
                            contacts: [
                                {
                                    remark: null,
                                    phone: null,
                                }
                            ],
                        },
                    },
                },
            };
            this.display = n !== null;
            this.detail(n);
        });
        const unwatchSrcDetailDisplay = this.$watch('$store.state.src_detail_display', function (n) {

            this.display = false;
        });
    }
}
</script>