<template>
    <AdminTop/>
    <div v-if="adminLinkDisplay">

        <router-link to="/src">
            <nut-button type="primary">安全应急响应中心</nut-button>
        </router-link>&nbsp;
        <router-link to="/adminOrder">
            <nut-button type="primary">用户订单管理</nut-button>
        </router-link>&nbsp;
        <router-link to="/test">
            <nut-button type="primary">数据大屏</nut-button>
        </router-link>&nbsp;
        <router-link to="/i">
            <nut-button type="primary">数据导出</nut-button>
        </router-link>&nbsp;

    </div>
    <router-view v-if="$route.fullPath !== '/'" :key="$route.fullPath"></router-view>
</template>

<script>
import AdminTop from "@/components/admin/AdminTop.vue";

export default {
    name: "Admin",
    components: {AdminTop},
    data() {
        return {
            adminLinkDisplay: false,
        }
    },
    methods: {},
    beforeMount() {
        const unwatchAdminToken = this.$watch('$store.state.admin_token', function (n) {
            if (n) {
                this.adminLinkDisplay = true;
                unwatchAdminToken();
            }
        });
    }
}
</script>

<style scoped>

</style>