<template>
    <nut-popup v-model:visible="updPasswordPopupDisplay" :style="{ height: '60%' }" position="bottom">
        <nut-form>
            <nut-form-item label="密码">
                <nut-input v-model="select_password" class="nut-input-text" placeholder="请输入密码" type="password"/>
            </nut-form-item>
            <nut-form-item label="确认密码">
                <nut-input v-model="rePassword" class="nut-input-text" placeholder="请确认密码" type="password"/>
            </nut-form-item>
        </nut-form>
        <nut-button @click="updPassword">确认修改</nut-button>
    </nut-popup>

    <nut-cell type="primary" @click="reqUpdPassword">修改密码</nut-cell>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
    name: "UpdPassword",
    data() {
        return {
            rePassword: null,
            select_password: null,
            updPasswordPopupDisplay: false
        }
    },
    methods: {
        passCheck() {
            if (!/^.{3,20}$/.test(this.select_password)) {
                console.log('密码格式错误，请重新输入');
                alert('密码码格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },
        rePassCheck() {
            if (this.rePassword !== (this.select_password)) {
                console.log('两次输入密码不一致');
                alert('两次输入密码不一致')
                return false;
            } else {
                return true;
            }
        },
        reqUpdPassword() {
            this.updPasswordPopupDisplay = true;
        },
        updPassword() {
            if (!this.passCheck()) {
                return;
            }
            if (!this.rePassCheck()) {
                return;
            }
            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/modify/password',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'password': this.select_password,
                }
            })
                .then((response) => {
                    alert('密码更改成功，返回到未登录状态')
                    console.log(response)
                    Cookies.remove('user_token')
                    location.reload()
                })
                .catch((error) => {
                    console.log('更改失败')
                    console.log(error)
                });
        }
    }
}
</script>

<style scoped>

</style>