<template>
    <nut-navbar
            :border="true"
            :fixed="true"
            :placeholder="true"
            :safe-area-inset-top="true"
            :title="title"
            @on-click-back="this.$store.commit('setSrcPauseState', !this.$store.state.src_pause_state)"
    >
        <template #left>
            <icon-link-break :fill="pauseState" size="18" theme="outline"/>
        </template>

        <template #right>
            <icon-database-network-point :fill="state" size="18" theme="outline"/>
        </template>
    </nut-navbar>
</template>

<script>
export default {
    name: "Nav",
    data() {
        return {
            title: '安全应急响应中心',
            pauseState: '#4fc08d',
            state: '#333'
        }
    },
    mounted() {
        const unwatchSrcState = this.$watch('$store.state.src_state', function (n) {
            this.state = n ? '#4fc08d' : '#fa2c19';
        });
        const unwatchSrcPauseState = this.$watch('$store.state.src_pause_state', function (n) {
            this.pauseState = n ? '#fa2c19' : '#4fc08d';
        });
    }
}
</script>