<template>
    <nut-cell>
        <nut-image :radius="10" :show-error="true" :src="mapUrl" height="100%" round/>
    </nut-cell>
</template>

<script>
export default {
    name: "GeoMap",
    data() {
        return {
            mapUrl: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0yNCA0NEMzNS4wNDU3IDQ0IDQ0IDM1LjA0NTcgNDQgMjRDNDQgMTIuOTU0MyAzNS4wNDU3IDQgMjQgNEMxMi45NTQzIDQgNCAxMi45NTQzIDQgMjRDNCAzNS4wNDU3IDEyLjk1NDMgNDQgMjQgNDRaIiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTI0IDEyVjE1IiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTMyLjQ4NTIgMTUuNTE0N0wzMC4zNjM5IDE3LjYzNiIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0zNiAyNEgzMyIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0zMi40ODUyIDMyLjQ4NTNMMzAuMzYzOSAzMC4zNjQiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMjQgMzZWMzMiIHN0cm9rZT0iIzAwMDAwMCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMTUuNTE0OCAzMi40ODUzTDE3LjYzNjEgMzAuMzY0IiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTEyIDI0SDE1IiBzdHJva2U9IiMwMDAwMDAiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PHBhdGggZD0iTTE1LjUxNDggMTUuNTE0N0wxNy42MzYxIDE3LjYzNiIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==',
            pointsInfo: []
        }
    },
    methods: {
        loadMap() {
            this.pointsInfo.push([this.$store.state.geo_lon, this.$store.state.geo_lat, this.$store.state.geo_speed]);

            const center = this.$store.state.geo_lon + ',' + this.$store.state.geo_lat;
            const width = 350;
            const height = 350;
            const zoom = 18;
            const layers = 'vec_c,cva_c';
            let markers = null;
            let markerStyles = null;
            let paths = null;
            const tk = '7de2b474a16ad8ce3099e03f22701dba';

            const points = this.pointsInfo.slice(-10);
            for (const key in points) {
                let lng = points[key][0];
                let lat = points[key][1];
                let speed = points[key][2];
                markers += '|' + lng + ',' + lat;
                markerStyles += '|-1,' + this.pointsInfo.length + ',' + encodeURIComponent(speed + 'km/h');
                paths += ';' + lng + ',' + lat;
            }
            markers = markers.substring(5);
            markerStyles = markerStyles.substring(5);
            paths = paths.substring(5);

            this.mapUrl = '//api.tianditu.gov.cn/staticimage?' +
                'center=' + center + '&' +
                'width=' + width + '&' +
                'height=' + height + '&' +
                'zoom=' + zoom + '&' +
                'layers=' + layers + '&' +
                'markers=' + markers + '&' +
                'markerStyles=' + markerStyles + '&' +
                'paths=' + paths + '&' +
                'tk=' + tk;
        }
    },
    mounted() {
        const unwatchGeoSpeed = this.$watch('$store.state.geo_speed', function (n, o) {
            if (n !== 0 || (n === 0 && o !== n)) {
                this.loadMap();
            }
        });
    }
}
</script>