<template>
    <div/>
</template>

<script>
import Stomp from 'stompjs'

export default {
    name: "Mq",
    data() {
        return {
            client: null,
            catch_timestamp: null
        }
    },
    mounted() {
        const unwatchMqState = this.$watch('$store.state.mq_state', function (n) {
            if (n === false) {
                this.connect();
            }
        });
        setInterval(() => {
            if (this.$store.state.mq_state) {
                const vuexData = this.$store.state;
                const data = {
                    from: 'client',
                    timestamp: new Date().getTime(),
                    itinerary_uuid: vuexData.itinerary_uuid,
                    sensor: {
                        acc: {
                            acc_x: vuexData.acc_x,
                            acc_y: vuexData.acc_y,
                            acc_z: vuexData.acc_z,
                        },
                        acc_g: {
                            acc_g_x: vuexData.acc_g_x,
                            acc_g_z: vuexData.acc_g_z,
                            acc_g_y: vuexData.acc_g_y,
                        },
                        ori: {
                            ori_a: vuexData.ori_alpha,
                            ori_b: vuexData.ori_beta,
                            ori_g: vuexData.ori_gamma,
                        },
                        rot: {
                            rot_x: vuexData.rot_x,
                            rot_y: vuexData.rot_y,
                            rot_z: vuexData.rot_z,
                        }
                    },
                    geo: {
                        geo_ts: vuexData.geo_ts,
                        geo_lon: vuexData.geo_lon,
                        geo_lat: vuexData.geo_lat,
                        geo_addr: vuexData.loc_format_addr,
                        geo_acc: vuexData.geo_acc,
                        geo_alt: vuexData.geo_alt,
                        geo_heading: vuexData.geo_heading,
                        geo_speed: vuexData.geo_speed,
                    },
                    other: {
                        weather: {
                            weather_code: vuexData.weather_code,
                            weather_temp: vuexData.weather_temp,
                        }
                    }
                };
                this.send('normal', JSON.stringify(data));
                this.catch_timestamp = data.timestamp;
            }
        }, 2000);
        const unwatchWarningState = this.$watch('$store.state.warning_state', (n) => {
            if (n) {
                this.$store.commit('setAlertTs', this.catch_timestamp);
                this.uploadNormal();
            }
        });
        const unwatchDangerState = this.$watch('$store.state.danger_state', (n) => {
            if (n) {
                this.$store.commit('setAlertState', 3);
            }
        });
        const unwatchAlertState = this.$watch('$store.state.alert_state', (n, o) => {
            if (n === 3 && o === 2) {
                this.uploadAlertedAndCanceled('danger', 'determine/action');
            } else if (n === 0 && o === 2) {
                this.uploadAlertedAndCanceled('warning', 'cancel/action');
            } else if (n === 0 && o === 3) {
                this.uploadAlertedAndCanceled('danger', 'cancel/action');
            }
        });
    },
    methods: {
        uploadNormal() {
            const vuexData = this.$store.state;
            const data = {
                from: 'client',
                type: 'suspect',
                itinerary_uuid: vuexData.itinerary_uuid,
                timestamp: new Date().getTime(),
                ref_timestamp: vuexData.alert_ts,
                recording: vuexData.alert_recording,
            }
            this.send('warning', JSON.stringify(data));
            setTimeout(() => {
                if (this.$store.state.warning_state) {
                    this.$store.commit('setDangerState', true);
                }
            }, 60000)
        },
        uploadAlertedAndCanceled(mainType, subType) {
            const vuexData = this.$store.state;
            const data = {
                from: 'client',
                type: subType,
                itinerary_uuid: vuexData.itinerary_uuid,
                timestamp: new Date().getTime(),
                ref_timestamp: vuexData.alert_ts
            }
            this.send(mainType, JSON.stringify(data));
        },
        onConnected() {
            this.$store.commit('setMqState', true);
            this.client.subscribe('/exchange/vehicle.monitor/' + this.$store.state.itinerary_uuid + '.*', this.callback, this.onFailed);
        },
        onFailed(frame) {
            console.log("RabbitMQ Err", frame);
            setTimeout(() => {
                this.connect();
            }, 1000);
        },
        send(type, msg) {
            this.client.send('/exchange/vehicle.monitor/' + this.$store.state.itinerary_uuid + '.' + type, {}, msg);
        },
        callback(frame) {
            if (JSON.parse(frame.body).from === 'server') {
                const destination = frame.headers.destination;
                const message = JSON.parse(frame.body);
                switch (destination.substring(destination.lastIndexOf('.') + 1, destination.length)) {
                    case 'normal':
                        this.$store.commit('setNormalTs', message.timestamp);
                        this.$store.commit('setNormalPr', message.pr);
                        if (
                            message.pr > 69.99 &&
                            this.$store.state.alert_state === 0
                        ) {
                            this.$store.commit('setAlertTs', message.timestamp);
                            this.$store.commit('setAlertState', 1);
                        }
                        break;
                    case 'warning':
                        switch (message.type) {
                            case 'cancel':
                                if (
                                    this.$store.state.alert_state === 2 &&
                                    this.$store.state.alert_ts === message.ref_timestamp
                                ) {
                                    this.$store.commit('setWarningState', false);
                                    this.$store.commit('setAlertState', 0);
                                }
                                break;
                        }
                        break;
                    case 'danger':
                        switch (message.type) {
                            case 'determine':
                                if (
                                    this.$store.state.alert_state === 2 &&
                                    this.$store.state.alert_ts === message.ref_timestamp
                                ) {
                                    this.$store.commit('setWarningState', false);
                                    this.$store.commit('setDangerState', true);
                                    this.$store.commit('setAlertState', 3);
                                }
                                break;
                            case 'cancel':
                                if (
                                    this.$store.state.alert_state === 3 &&
                                    this.$store.state.alert_ts === message.ref_timestamp
                                ) {
                                    this.$store.commit('setDangerState', false);
                                    this.$store.commit('setAlertState', 0)
                                }
                                break;
                        }
                        break;
                }
            }
        },
        connect() {
            this.client = Stomp.client('ws://8.130.14.173:15674/ws')
            this.client.debug = false;
            const headers = {
                login: 'vue',
                passcode: 'a3C%&V#jwrGjfeuM',
                host: '/vmas'
            };
            this.client.connect(headers, this.onConnected, this.onFailed);
        }
    }
}
</script>