<template>
    <nut-popup v-model:visible="visible" :style="{ height: '55%' }" closeable position="bottom" round @close="close">
        <nut-tabs v-model="state.value" direction="vertical" style="height:100%" title-scroll>
            <nut-tab-pane :title="'设备信息'">
                <DeviceUuidQr/>
            </nut-tab-pane>
            <nut-tab-pane :title="'行程信息'">
                <ItineraryUuidQr/>
            </nut-tab-pane>
            <nut-tab-pane :title="'结束行程'">
                <router-link to="/vehicle/finish">
                    <nut-button type="danger">确定结束行程</nut-button>
                </router-link>
            </nut-tab-pane>
        </nut-tabs>
    </nut-popup>
</template>

<script>
import {reactive} from "vue";
import DeviceUuidQr from "@/components/vehicle/detail/DeviceUuidQr.vue";
import ItineraryUuidQr from "@/components/vehicle/detail/ItineraryUuidQr.vue";

export default {
    name: "Detail",
    components: {ItineraryUuidQr, DeviceUuidQr},
    data() {
        return {
            visible: false,
            device_uuid: '设备UUID',
            itinerary_uuid: '行程UUID（暂未生成）'
        }
    },
    methods: {
        close() {
            this.visible = false;
            this.$store.commit('setDetailState', false);
        }
    },
    mounted() {
        const unwatchDetailState = this.$watch('$store.state.detail_state', function (n) {
            if (n) {
                this.visible = true;
            }
        });
    },
    setup() {
        const tabsState = reactive({
            value: '0',
        });
        return {state: tabsState};
    }
}
</script>