<template>
    <nut-cell>
        <nut-tabs v-model="tabState.value" :title-scroll="true" style="width: 100%" type="smile">
            <nut-tab-pane title="指南针">
                <OriAlpha/>
            </nut-tab-pane>
            <nut-tab-pane title="正向角度">
                <OriBeta/>
            </nut-tab-pane>
            <nut-tab-pane title="侧向角度">
                <OriGamma/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import OriAlpha from "@/components/vehicle/tab/sensor/orientation/OriAlpha.vue";
import OriBeta from "@/components/vehicle/tab/sensor/orientation/OriBeta.vue";
import OriGamma from "@/components/vehicle/tab/sensor/orientation/OriGamma.vue";

export default {
    name: "Orientation",
    components: {OriAlpha, OriBeta, OriGamma},
    setup() {
        const tabState = reactive({
            value: '0',
        });
        return {tabState};
    },
}
</script>