<template>
    <div v-show="dataDisplay">
        <nut-row>
            <nut-col :span="12">
                <nut-cell :desc="uuid" title="行程UUID"></nut-cell>
            </nut-col>
            <nut-col :span="12">
                <nut-cell :desc="queryTime" title="查询时间"></nut-cell>
            </nut-col>
        </nut-row>

        <div style="margin-bottom: 10px;text-align: center">
            <nut-button shape="square" size="small" type="info" @click="printAll">打印</nut-button>&nbsp;
            <nut-button shape="square" size="small" type="info" @click="printAdv">导出</nut-button>&nbsp;
        </div>

        <vxe-table ref="xTable" :data="tableData" :loading="loadState"
                   :print-config="tablePrint" :row-config="{isCurrent: true, isHover: true}"
                   :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
                   :sort-config="{multiple: true, chronological: true}"
                   border round show-overflow size="mini"
                   stripe>
            <vxe-column fixed="left" title="序号" type="seq" width="50"></vxe-column>

            <vxe-colgroup title="基本信息">
                <vxe-column field="timestamp" fixed="left" sortable title="数据点时间戳" width="160"></vxe-column>
            </vxe-colgroup>

            <vxe-colgroup title="监测数据">
                <vxe-colgroup title="加速度">
                    <vxe-column field="acc_x" sortable title="X轴加速度 (m/s²)" width="80"></vxe-column>
                    <vxe-column field="acc_y" sortable title="Y轴加速度 (m/s²)" width="80"></vxe-column>
                    <vxe-column field="acc_z" sortable title="Z轴加速度 (m/s²)" width="80"></vxe-column>
                    <vxe-column field="acc_g_x" sortable title="侧向G值" width="70"></vxe-column>
                    <vxe-column field="acc_g_y" sortable title="垂直G值" width="70"></vxe-column>
                    <vxe-column field="acc_g_z" sortable title="正向G值" width="70"></vxe-column>
                </vxe-colgroup>
                <vxe-colgroup title="偏转角">
                    <vxe-column field="ori_alpha" sortable title="设备朝向 (°)" width="70"></vxe-column>
                    <vxe-column field="ori_beta" sortable title="正向角度 (°)" width="70"></vxe-column>
                    <vxe-column field="ori_gamma" sortable title="侧向角度 (°)" width="70"></vxe-column>
                </vxe-colgroup>
                <vxe-colgroup title="旋转速率">
                    <vxe-column field="rot_x" sortable title="X轴旋转速率 (°/s)" width="90"></vxe-column>
                    <vxe-column field="rot_y" sortable title="Y轴旋转速率 (°/s)" width="90"></vxe-column>
                    <vxe-column field="rot_z" sortable title="Z轴旋转速率 (°/s)" width="90"></vxe-column>
                </vxe-colgroup>
                <vxe-colgroup title="地理信息">
                    <vxe-column field="geo_ts" sortable title="地理位置时间戳" width="160"></vxe-column>
                    <vxe-column field="geo_lon" sortable title="经度 (°)" width="70"></vxe-column>
                    <vxe-column field="geo_lat" sortable title="纬度 (°)" width="70"></vxe-column>
                    <vxe-column field="geo_addr" sortable title="定位地址" width="200"></vxe-column>
                    <vxe-column field="geo_acc" sortable title="位置精度 (m)" width="70"></vxe-column>
                    <vxe-column field="geo_alt" sortable title="海拔 (m)" width="60"></vxe-column>
                    <vxe-column field="geo_heading" sortable title="行驶方向 (°)" width="70"></vxe-column>
                    <vxe-column field="geo_speed" sortable title="速度&emsp;&emsp;&emsp;(km/h)" width="80"></vxe-column>
                </vxe-colgroup>
            </vxe-colgroup>

            <vxe-colgroup title="其他信息">
                <vxe-colgroup title="天气">
                    <vxe-column field="weather_code" sortable title="天气" width="100">
                        <template #default="{ row }">
                            <span v-if="row.weather_code===0" style="color: green">晴（国内城市白天晴）</span>
                            <span v-else-if="row.weather_code===1" style="color: green">晴（国内城市夜晚晴）</span>
                            <span v-else-if="row.weather_code===2" style="color: green">晴（国外城市白天晴）</span>
                            <span v-else-if="row.weather_code===3" style="color: green">晴（国外城市夜晚晴）</span>
                            <span v-else-if="row.weather_code===4" style="color: green">多云</span>
                            <span v-else-if="row.weather_code===5" style="color: green">晴间多云</span>
                            <span v-else-if="row.weather_code===6" style="color: green">晴间多云</span>
                            <span v-else-if="row.weather_code===7" style="color: green">大部多云</span>
                            <span v-else-if="row.weather_code===8" style="color: green">大部多云</span>
                            <span v-else-if="row.weather_code===9" style="color: green">阴</span>
                            <span v-else-if="row.weather_code===10" style="color: cadetblue">阵雨</span>
                            <span v-else-if="row.weather_code===11" style="color: cadetblue">雷阵雨</span>
                            <span v-else-if="row.weather_code===12" style="color: red">雷阵雨伴有冰雹</span>
                            <span v-else-if="row.weather_code===13" style="color: cadetblue">小雨</span>
                            <span v-else-if="row.weather_code===14" style="color: cadetblue">中雨</span>
                            <span v-else-if="row.weather_code===15" style="color: cadetblue">大雨</span>
                            <span v-else-if="row.weather_code===16" style="color: cadetblue">暴雨</span>
                            <span v-else-if="row.weather_code===17" style="color: cadetblue">大暴雨</span>
                            <span v-else-if="row.weather_code===18" style="color: cadetblue">特大暴雨</span>
                            <span v-else-if="row.weather_code===19" style="color: cadetblue">冻雨</span>
                            <span v-else-if="row.weather_code===20" style="color: orange">雨夹雪</span>
                            <span v-else-if="row.weather_code===21" style="color: orange">阵雪</span>
                            <span v-else-if="row.weather_code===22" style="color: orange">小雪</span>
                            <span v-else-if="row.weather_code===23" style="color: orange">中雪</span>
                            <span v-else-if="row.weather_code===24" style="color: orange">大雪</span>
                            <span v-else-if="row.weather_code===25" style="color: orange">暴雪</span>
                            <span v-else-if="row.weather_code===26" style="color: cadetblue">浮尘</span>
                            <span v-else-if="row.weather_code===27" style="color: cadetblue">扬沙</span>
                            <span v-else-if="row.weather_code===28" style="color: cadetblue">沙尘暴</span>
                            <span v-else-if="row.weather_code===29" style="color: cadetblue">强沙尘暴</span>
                            <span v-else-if="row.weather_code===30" style="color: cadetblue">雾</span>
                            <span v-else-if="row.weather_code===31" style="color: cadetblue">霾</span>
                            <span v-else-if="row.weather_code===32" style="color: cadetblue">风</span>
                            <span v-else-if="row.weather_code===33" style="color: cadetblue">大风</span>
                            <span v-else-if="row.weather_code===34" style="color: cadetblue">飓风</span>
                            <span v-else-if="row.weather_code===35" style="color: red">热带风暴</span>
                            <span v-else-if="row.weather_code===36" style="color: red">龙卷风</span>
                            <span v-else-if="row.weather_code===37" style="color: green">冷</span>
                            <span v-else-if="row.weather_code===38" style="color: green">热</span>
                            <span v-else style="color: cadetblue">未知</span>
                        </template>
                    </vxe-column>
                    <vxe-column field="weather_temp" sortable title="温度 (℃)" width="60"></vxe-column>
                </vxe-colgroup>
            </vxe-colgroup>

            <vxe-colgroup fixed="right" title="预警信息">
                <vxe-column field="pr" fixed="right" sortable title="事故概率" width="70">
                    <template #default="{ row }">
                        <span v-if="row.pr<33.33" style="color: green">{{ row.pr }}%</span>
                        <span v-else-if="row.pr<66.66" style="color: orange">{{ row.pr }}%</span>
                        <span v-else-if="row.pr<100" style="color: red">{{ row.pr }}%</span>
                    </template>
                </vxe-column>
                <vxe-column field="alerted" fixed="right" sortable title="预警状态" width="80">
                    <template #default="{ row }">
            <span v-if="row.alerted==null&&row.canceled==null"><icon-people-safe fill="#800080FF" size="18"
                                                                                 theme="outline"/> 未预警</span>
                        <span v-else-if="row.alerted==null"><icon-help fill="#FFA500FF" size="18" theme="outline"/> 未知</span>
                        <span v-else-if="row.alerted"><icon-close-one fill="#008000FF" size="18"
                                                                      theme="outline"/> 否</span>
                        <span v-else-if="!row.alerted"><icon-check-one fill="#FF0000FF" size="18"
                                                                       theme="outline"/> 是</span>
                    </template>
                </vxe-column>
                <vxe-column field="canceled" fixed="right" sortable title="已取消预警" width="80">
                    <template #default="{ row }">
            <span v-if="row.alerted==null&&row.canceled==null"><icon-people-safe fill="#800080FF" size="18"
                                                                                 theme="outline"/> 未预警</span>
                        <span v-else-if="row.canceled==null"><icon-help fill="#FFA500FF" size="18" theme="outline"/> 未知</span>
                        <span v-else-if="!row.canceled"><icon-close-one fill="#FF0000FF" size="18" theme="outline"/> 否</span>
                        <span v-else-if="row.canceled"><icon-check-one fill="#008000FF" size="18"
                                                                       theme="outline"/> 是</span>
                    </template>
                </vxe-column>
            </vxe-colgroup>
        </vxe-table>

        <vxe-pager
            :current-page="tablePage.currentPage"
            :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
            :loading="loadState"
            :page-size="tablePage.pageSize"
            :total="tablePage.totalResult"
            @page-change="pageChange">
        </vxe-pager>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import {showNotify} from '@nutui/nutui';
import '@nutui/nutui/dist/packages/notify/style';

export default {
    name: "DetailTable",
    setup() {
        const primaryNotify = (msg) => {
            showNotify.primary(msg);
        };
        const successNotify = (msg) => {
            showNotify.success(msg);
        };
        const errorNotify = (msg) => {
            showNotify.danger(msg);
        };
        const warningNotify = (msg) => {
            showNotify.warn(msg);
        };
        return {
            primaryNotify,
            successNotify,
            errorNotify,
            warningNotify,
        }
    },
    methods: {
        pageChange({currentPage, pageSize}) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.getData();
        },
        getData() {
            this.loadState = true;
            this.dataDisplay = true;

            axios({
                method: 'get',
                url: 'https://8.130.14.173/vehicle/itinerary/detail',
                params: {
                    'id': this.uuid,
                }
            })
                .then((response) => {
                    const list = response.data;
                    this.loadState = false;
                    this.tablePage.totalResult = list.length;
                    this.tableData = list.slice((this.tablePage.currentPage - 1) * this.tablePage.pageSize, this.tablePage.currentPage * this.tablePage.pageSize)
                    this.successNotify('行程信息查询成功！');
                })
                .catch((error) => {
                    this.errorNotify('行程信息查询失败：' + error.response.data.msg);
                });
        },
        printAll() {
            this.$refs.xTable.print();
        },
        printAdv() {
            this.$refs.xTable.openExport();
        }
    },
    data() {
        return {
            uuid: null,
            queryTime: null,
            loadState: true,
            dataDisplay: false,
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
            },
            tableData: [],
            tablePrint: {}
        }
    },
    mounted() {
        const unwatchIUuid = this.$watch('$store.state.i_uuid', function (n) {
            this.uuid = n;
            this.queryTime = moment().format('YYYY-MM-DD HH:mm:ss:SSS');
            this.getData();
        });
    }
}
</script>