<template>
    <nut-tabbar :bottom="true" :safe-area-inset-bottom="true" @tab-switch="tabSwitch">
        <nut-tabbar-item id="target1" tab-title="个人信息">
            <template #icon="props">
                <icon-permissions v-if="props.active === true" fill="#2c68ff" size="22" theme="outline"/>
                <icon-permissions v-if="props.active === false" fill="#000000" size="22" theme="outline"/>
            </template>
        </nut-tabbar-item>
        <nut-tabbar-item id="target2" tab-title="服务相关">
            <template #icon="props">
                <icon-buy v-if="props.active === true" fill="#2c68ff" size="22" theme="outline"/>
                <icon-buy v-if="props.active === false" fill="#000000" size="22" theme="outline"/>
            </template>
        </nut-tabbar-item>
        <nut-tabbar-item id="target3" tab-title="行程信息">
            <template #icon="props">
                <icon-road-cone v-if="props.active === true" fill="#2c68ff" size="22" theme="outline"/>
                <icon-road-cone v-if="props.active === false" fill="#000000" size="22" theme="outline"/>
            </template>
        </nut-tabbar-item>

    </nut-tabbar>

</template>

<script>

import {useStore} from "vuex";

export default {
    name: "UserTab",

    setup() {
        const store = useStore();

        function hideAll() {
            store.commit('setInfoTab', false);
            store.commit('setOrderTab', false);
            store.commit('setItiTab', false);
        }

        function tabSwitch(item, index) {
            switch (index) {
                case 0:
                    hideAll();
                    store.commit('setInfoTab', true);
                    break;
                case 1:
                    hideAll();
                    store.commit('setOrderTab', true);
                    break;
                case 2:
                    hideAll();
                    store.commit('setItiTab', true);
                    break;

            }
        }

        return {
            tabSwitch,

        };
    }
}
</script>
<style>

</style>