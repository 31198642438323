<template>
    <div>
        <AdminLogin/>
    </div>
</template>

<script>
import AdminLogin from "@/components/admin/AdminLogin.vue";

export default {
    name: "AdminBlock",
    components: {AdminLogin}
}
</script>

<style scoped>

</style>