<template>
    <nut-watermark v-if=watermarkVisible :content=watermark :font-size=10 :height=80 :width=180></nut-watermark>
    <div>
        <Alarm/>
        <AlarmAwait/>
        <Warning/>
    </div>
    <Detail/>
    <div>
        <InActivation/>
        <ActivationSuccess/>
        <ActivationFailure/>
    </div>
    <div>
        <SystemCheck/>
    </div>

    <Nav/>
    <nut-backtop :bottom="60"/>
    <div>
        <Status/>
        <Geo/>
        <Sensor/>
        <OtherInfo/>
    </div>
    <Footer/>
    <Tab/>
    <Monitor/>
    <Mq/>
</template>

<script>
import Nav from "@/components/vehicle/Nav.vue";
import * as FingerprintJS from "@fingerprintjs/fingerprintjs";
import axios from "axios";
import Footer from "@/components/vehicle/Footer.vue";
import InActivation from "@/components/vehicle/activation/InActivation.vue";
import ActivationSuccess from "@/components/vehicle/activation/ActivationSuccess.vue";
import ActivationFailure from "@/components/vehicle/activation/ActivationFailure.vue";
import Detail from "@/components/vehicle/detail/Detail.vue";
import SystemCheck from "@/components/vehicle/check/SystemCheck.vue";
import Tab from "@/components/vehicle/tab/Tab.vue";
import Mq from "@/components/vehicle/monitor/Mq.vue";
import Sensor from "@/components/vehicle/tab/sensor/Sensor.vue";
import Monitor from "@/components/vehicle/monitor/Monitor.vue";
import Geo from "@/components/vehicle/tab/geo/Geo.vue";
import OtherInfo from "@/components/vehicle/tab/other/OtherInfo.vue";
import Status from "@/components/vehicle/tab/status/Status.vue";
import Warning from "@/components/vehicle/alarm/Warning.vue";
import AlarmAwait from "@/components/vehicle/alarm/AlarmAwait.vue";
import Alarm from "@/components/vehicle/alarm/Alarm.vue";

export default {
    name: "Vehicle",
    components: {
        Alarm,
        AlarmAwait,
        Warning,
        Status,
        OtherInfo,
        Geo,
        Monitor,
        Sensor,
        Mq,
        Tab,
        SystemCheck,
        Detail,
        ActivationFailure,
        ActivationSuccess,
        InActivation,
        Footer,
        Nav
    },
    data() {
        return {
            watermark: null,
            watermarkVisible: false,
            monitorVisible: false
        }
    },
    methods: {
        async init() {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            this.$store.commit('setDeviceUUID', result.visitorId);
        },
        active(token, user, contacts) {
            this.$store.commit('setActiveState', true);
            this.$store.commit('setToken', token);
            this.$store.commit('setUser', user);
            this.$store.commit('setContacts', contacts);
        }
    },
    beforeMount() {
        const unwatchDeviceUUID = this.$watch('$store.state.device_uuid', function (n) {
            if (n !== null) {
                // 取消设备UUID监听
                unwatchDeviceUUID();
                this.watermark = n.toString();
                this.watermarkVisible = true;
                axios({
                    method: 'post',
                    url: 'https://8.130.14.173/vehicle/verify',
                    params: {
                        uuid: n
                    }
                })
                    .then((response) => {
                        this.active(
                            response.headers.authorization,
                            response.data.user_info.id,
                            response.data.user_info.contacts,
                        );
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$store.commit('setActiveState', error.response === undefined ? error.code : error.response.data.msg);
                    });
            }
        });
    },
    mounted() {
        this.init();
    }
}
</script>