<template>
    <nut-divider style="text-align: center">
        <span>机动车行驶对象自动监测报警系统<br><small>部署时间 2023/1/17 19:29</small></span>
    </nut-divider>
</template>

<script>
export default {
    name: "Footer"
}
</script>