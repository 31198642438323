<template>
    <nut-cell v-if="visible">
        <nut-tabs v-model="tabState.value" :title-scroll="true" size="large" style="width: 100%" type="smile">

            <nut-tab-pane style="padding: 0;" title="路径地图">
                <GeoMap/>
            </nut-tab-pane>

            <nut-tab-pane style="padding: 0;" title="地理信息">
                <GeoInfo/>
            </nut-tab-pane>

            <nut-tab-pane style="padding: 0;" title="位置信息">
                <LocInfo/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import GeoMap from "@/components/vehicle/tab/geo/GeoMap.vue";
import GeoInfo from "@/components/vehicle/tab/geo/GeoInfo.vue";
import LocInfo from "@/components/vehicle/tab/geo/LocInfo.vue";

export default {
    name: "Geo",
    components: {LocInfo, GeoInfo, GeoMap},
    data() {
        return {
            visible: false
        }
    },
    setup() {
        const tabState = reactive({
            value: '0',
        });

        return {tabState};
    },
    mounted() {
        const unwatchGeoTab = this.$watch('$store.state.geo_tab', function (n) {
            this.visible = !!n;
        });
    }
}
</script>