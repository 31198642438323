<template>
    <Nav/>
    <Ws/>
    <nut-row>
        <nut-col :span="12">
            <WarningData/>
        </nut-col>
        <nut-col :span="12">
            <WarningDetail/>
        </nut-col>
    </nut-row>

    <Footer/>
</template>

<script>
import Nav from "@/components/src/Nav.vue";
import Footer from "@/components/vehicle/Footer.vue";
import Ws from "@/components/src/Ws.vue";
import WarningData from "@/components/src/WarningData.vue";
import WarningDetail from "@/components/src/WarningDetail.vue";

export default {
    name: "SRC",
    components: {
        WarningDetail,
        WarningData,
        Ws,
        Nav,
        Footer
    }
}
</script>