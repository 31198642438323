<template>
    <nut-cell>
        <nut-tabs v-model="tabState.value" :title-scroll="true" style="width: 100%" type="smile">
            <nut-tab-pane title="X轴">
                <AccX/>
            </nut-tab-pane>
            <nut-tab-pane title="Y轴">
                <AccY/>
            </nut-tab-pane>
            <nut-tab-pane title="Z轴">
                <AccZ/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import AccZ from "@/components/vehicle/tab/sensor/acceleration/AccZ.vue";
import AccY from "@/components/vehicle/tab/sensor/acceleration/AccY.vue";
import AccX from "@/components/vehicle/tab/sensor/acceleration/AccX.vue";

export default {
    name: "Acceleration",
    components: {AccZ, AccY, AccX},
    setup() {
        const tabState = reactive({
            value: '0',
        });
        return {tabState};
    },
}
</script>