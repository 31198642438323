<template>
    <nut-popup v-model:visible="loginPopupDisplay" :style="{ height: '60%' }" position="bottom">
        <nut-form>
            <nut-form-item label="手机号">
                <nut-input v-model="phone" class="nut-input-text" placeholder="请输入手机号" type="text"/>
            </nut-form-item>
            <nut-form-item label="密码">
                <nut-input v-model="password" class="nut-input-text" placeholder="请输入密码" type="password"/>
            </nut-form-item>
        </nut-form>
        <nut-button @click="login">确认登录</nut-button>
    </nut-popup>

    <nut-cell type="primary" @click="reqLogin">登录</nut-cell>
</template>

<script>
import axios from "axios";
import Cookies from 'js-cookie'

export default {
    name: "Login",
    data() {
        return {
            phone: null,
            password: null,
            loginPopupDisplay: false
        }
    },
    methods: {
        phoneCheck() {
            if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone)) {
                console.log('电话号码格式错误，请重新输入');
                alert('电话号码格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },

        passCheck() {
            if (!/^.{3,20}$/.test(this.password)) {
                console.log('密码格式错误，请重新输入');
                alert('密码码格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },
        reqLogin() {
            this.loginPopupDisplay = true;
        },
        login() {

            if (!this.phoneCheck()) {
                return;
            }
            if (!this.passCheck()) {
                return;
            }
            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/checkLogin',
                params: {
                    'phone': this.phone,
                    'password': this.password,
                }
            })
                .then((response) => {
                    alert('登陆成功')
                    this.$store.commit('setUserToken', response.headers.authorization);
                    Cookies.set('user_token', response.headers.authorization, {expires: 7})
                })
                .catch((error) => {
                    alert('登陆失败')
                    console.log('登陆失败')
                    console.log(error)
                });
        }
    },
    mounted() {
        const unwatchUserToken = this.$watch('$store.state.user_token', function (n) {
            console.log('监听到Token变动' + n)
        });
        if (Cookies.get('user_token') !== undefined && Cookies.get('user_token') != null && Cookies.get('user_token') !== '') {
            this.$store.commit('setUserToken', Cookies.get('user_token'));
        }
    }
}
</script>

<style scoped>

</style>