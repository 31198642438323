<template>
    <nut-popup v-model:visible="logoutPopupDisplay" :style="{ height: '20%',width:'80%' }" round>
        <div>
            <div align="center"
                 style="font-size: 20px;color: darkorange;margin-top: 30px">是否确认注销?
            </div>
            <nut-button color="#1368D7" size="small" style="margin-left: 100px;margin-top: 50px"
                        @click="logout">确认注销
            </nut-button>
        </div>

    </nut-popup>

    <nut-cell type="primary" @click="reqLogout">注销</nut-cell>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
    name: "Logout",
    data() {
        return {
            logoutPopupDisplay: false
        }
    },
    methods: {
        reqLogout() {
            this.logoutPopupDisplay = true;
        },
        logout() {

            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/logOut',
            })
                .then((response) => {
                    alert('注销成功')
                    Cookies.remove('user_token')
                    console.log(response)
                    location.reload()

                })
                .catch((error) => {
                    console.log('注销失败')
                    console.log(error)
                });
        }
    },
    mounted() {
        const unwatchUserToken = this.$watch('$store.state.user_token', function (n) {
            console.log('监听到Token变动' + n)
        });
    }
}
</script>

<style scoped>

</style>