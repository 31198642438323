<template>
    <nut-cell center>
        <nut-image :show-error="true" :src="qrUrl" height="100%"/>
    </nut-cell>
    <nut-cell center>{{ deviceUuid }}</nut-cell>
</template>

<script>
import QRCode from "qrcode";

export default {
    name: "DeviceUuidQr",
    data() {
        return {
            deviceUuid: '设备UUID',
            qrUrl: null
        }
    },
    methods: {
        init() {
            QRCode.toDataURL(this.$store.state.device_uuid, {errorCorrectionLevel: 'H'})
                .then(url => {
                    this.qrUrl = url;
                })
                .catch(err => {
                    console.error(err)
                })
            this.deviceUuid = this.$store.state.device_uuid;
        }
    },
    mounted() {
        if (this.$store.state.active_state !== null) {
            this.init();
        } else {
            const unwatchActiveState = this.$watch('$store.state.active_state', function (n) {
                if (n !== null) {
                    this.init();
                    unwatchActiveState();
                }
            });
        }
    },
}
</script>