<template>
    <nut-cell v-if="visible">
        <nut-tabs v-model="tabState.value" :title-scroll="true" size="large" style="width: 100%" type="smile">

            <nut-tab-pane style="padding: 0;" title="加速度">
                <Acceleration/>
            </nut-tab-pane>

            <nut-tab-pane style="padding: 0;" title="偏转度">
                <Orientation/>
            </nut-tab-pane>

            <nut-tab-pane style="padding: 0;" title="G值">
                <G/>
            </nut-tab-pane>

            <nut-tab-pane style="padding: 0;" title="旋转速率">
                <Rotation/>
            </nut-tab-pane>
        </nut-tabs>
    </nut-cell>
</template>

<script>
import {reactive} from "vue";
import Acceleration from "@/components/vehicle/tab/sensor/acceleration/Acceleration.vue";
import Orientation from "@/components/vehicle/tab/sensor/orientation/Orientation.vue";
import G from "@/components/vehicle/tab/sensor/g/G.vue";
import Rotation from "@/components/vehicle/tab/sensor/rotation/Rotation.vue";

export default {
    name: "Sensor",
    components: {Rotation, G, Orientation, Acceleration},
    data() {
        return {
            visible: false
        }
    },
    setup() {
        const tabState = reactive({
            value: '0',
        });

        return {tabState};
    },
    mounted() {
        const unwatchSensorTab = this.$watch('$store.state.sensor_tab', function (n) {
            this.visible = !!n;
        });
    }
}
</script>