<template>
    <nut-cell desc="数据时间戳">
        <template v-slot:title>
            <span><icon-time fill="#000000" size="12" theme="outline"/>&nbsp;{{ ts }}</span>
        </template>
    </nut-cell>
    <nut-cell desc="位置精度">
        <template v-slot:title>
            <span><icon-focus fill="#000000" size="12" theme="outline"/>&nbsp;{{ acc }}m</span>
        </template>
    </nut-cell>
    <nut-cell desc="经度">
        <template v-slot:title>
            <span><icon-local fill="#000000" size="12" theme="outline"/>&nbsp;{{ lon }}°</span>
        </template>
    </nut-cell>
    <nut-cell desc="纬度">
        <template v-slot:title>
            <span><icon-local fill="#000000" size="12" theme="outline"/>&nbsp;{{ lat }}°</span>
        </template>
    </nut-cell>
    <nut-cell desc="行驶方向">
        <template v-slot:title>
            <span><icon-navigation fill="#000000" size="12" theme="outline"/>&nbsp;{{ heading }}°</span>
        </template>
    </nut-cell>
    <nut-cell desc="速度">
        <template v-slot:title>
            <span><icon-speed-one fill="#000000" size="12" theme="outline"/>&nbsp;{{ speed }}km/h</span>
        </template>
    </nut-cell>
    <nut-cell desc="海拔">
        <template v-slot:title>
            <span><icon-auto-height-one fill="#000000" size="12" theme="outline"/>&nbsp;{{ alt }}m</span>
        </template>
    </nut-cell>
</template>

<script>
import moment from "moment";

export default {
    name: "GeoInfo",
    data() {
        return {
            ts: this.$store.state.geo_ts,
            acc: this.$store.state.geo_acc,
            lon: this.$store.state.geo_lon,
            lat: this.$store.state.geo_lat,
            heading: this.$store.state.geo_heading,
            speed: this.$store.state.geo_speed,
            alt: this.$store.state.geo_alt
        }
    },
    mounted() {
        this.$watch('$store.state.geo_ts', (n) => {
            this.ts = moment(n).format("MM-DD HH:mm:ss.SSS")
        });
        this.$watch('$store.state.geo_acc', (n) => {
            this.acc = n
        });
        this.$watch('$store.state.geo_lon', (n) => {
            this.lon = n
        });
        this.$watch('$store.state.geo_lat', (n) => {
            this.lat = n
        });
        this.$watch('$store.state.geo_heading', (n) => {
            this.heading = n
        });
        this.$watch('$store.state.geo_speed', (n) => {
            this.speed = n
        });
        this.$watch('$store.state.geo_alt', (n) => {
            this.alt = n
        });
    }
}
</script>