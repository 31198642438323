<template>
    <div v-show="dataDisplay">
        <vxe-table ref="xTable" :data="tableData" :edit-config="{trigger: 'click', mode: 'row'}"
                   :loading="loadState" :print-config="tablePrint"
                   :row-config="{isCurrent: true, isHover: true}"
                   :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
                   :sort-config="{multiple: true, chronological: true}" border round show-overflow
                   size="mini"
                   stripe>
            <vxe-column fixed="left" title="序号" type="seq" width="50"></vxe-column>

            <vxe-colgroup title="基本信息">
                <vxe-column field="user" sortable title="用户名" width="80"></vxe-column>
            </vxe-colgroup>

            <vxe-colgroup title="详细信息">
                <vxe-colgroup title="订单信息">
                    <vxe-column field="id" sortable title="订单编号" width="80"></vxe-column>
                    <vxe-column field="pay_time" sortable title="支付时间" width="160"></vxe-column>
                    <vxe-column field="status" sortable title="订单状态" width="160">
                        <template #default="{ row }">
                            <span v-if="row.status===0" style="color: green">未订购 </span>
                            <span v-else-if="row.status===1" style="color: green">未发货</span>
                            <span v-else-if="row.status===2" style="color: green">已发货</span>
                            <span v-else-if="row.status===3" style="color: green">已收货</span>
                            <span v-else-if="row.status===4" style="color: green">订单异常</span>
                            <span v-else style="color: cadetblue">未知</span>
                        </template>
                    </vxe-column>

                </vxe-colgroup>
                <vxe-colgroup title="快递信息">
                    <vxe-column field="tracking_number" sortable title="快递单号" width="200">
                        <template #default="{ row }">
                            <span>{{ row.tracking_number }}
                            <icon-edit fill="#333" size="12" theme="outline"
                                       @click="scanPopupDisplay(row.tracking_number,row.pay_id)"/>
                            </span>
                        </template>
                    </vxe-column>
                    <vxe-column field="address" sortable title="地址" width="80"></vxe-column>
                    <vxe-column field="phone" sortable title="电话" width="160"></vxe-column>
                </vxe-colgroup>
            </vxe-colgroup>

            <vxe-colgroup title="支付信息">
                <vxe-colgroup title="付款Paypal信息">
                    <vxe-column field="pay_id" sortable title="支付订单编号" width="250"></vxe-column>
                    <vxe-column field="pay_token" sortable title="支付凭证" width="160"></vxe-column>
                </vxe-colgroup>

            </vxe-colgroup>


        </vxe-table>

        <vxe-pager
                :current-page="tablePage.currentPage"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                :loading="loadState"
                :page-size="tablePage.pageSize"
                :total="tablePage.totalResult"
                @page-change="pageChange">
        </vxe-pager>
    </div>

    <nut-popup v-model:visible="scanPopup" :style="{ height: '80%',width:'80%' }" :teleport-disable="true" round>
        <nut-button @click="scanBarcode">开启摄像头</nut-button>
        <video v-if="scan" ref="video" autoplay></video>
        <p v-if="scannedCode">快递单号：{{ scannedCode }}，请确认后再点击确认录入</p>
        <nut-button v-if="scannedCode" @click="reqLogistic">确认录入</nut-button>
    </nut-popup>
</template>

<script>
import axios from "axios";
import {showNotify} from "@nutui/nutui";
import {BrowserBarcodeReader} from "@zxing/library";

export default {
    name: "AdminOrder",
    setup() {
        const primaryNotify = (msg) => {
            showNotify.primary(msg);
        };
        const successNotify = (msg) => {
            showNotify.success(msg);
        };
        const errorNotify = (msg) => {
            showNotify.danger(msg);
        };
        const warningNotify = (msg) => {
            showNotify.warn(msg);
        };
        return {
            primaryNotify,
            successNotify,
            errorNotify,
            warningNotify,
        }
    },
    data() {
        return {
            scan: false,
            scannedCode: null,
            videoStream: null,
            deviceIdNum: 0,

            tracking_number: null,
            pay_id: null,

            scanPopup: false,
            loadState: true,
            dataDisplay: false,
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
            },
            tableData: [],
            tablePrint: {}
        }
    },
    methods: {
        scanPopupDisplay(tracking_number, pay_id) {
            this.tracking_number = tracking_number
            this.pay_id = pay_id
            alert(this.pay_id)
            this.scanPopup = true
        },
        pageChange({currentPage, pageSize}) {
            this.tablePage.currentPage = currentPage;
            this.tablePage.pageSize = pageSize;
            this.getOrderData();
        },
        getOrderData() {
            this.loadState = true;
            this.dataDisplay = true;

            axios({
                method: 'get',
                url: 'https://8.130.14.173/admin/reqOrderLogistic',
            })
                .then((response) => {
                    const list = response.data;
                    this.loadState = false;
                    this.tablePage.totalResult = list.length;
                    this.tableData = list.slice((this.tablePage.currentPage - 1) * this.tablePage.pageSize, this.tablePage.currentPage * this.tablePage.pageSize)
                    this.successNotify('订单信息查询成功！');
                })
                .catch((error) => {
                    this.errorNotify('订单信息查询失败：' + error.response.data.msg);
                });
        },
        scanBarcode() {
            const codeReader = new BrowserBarcodeReader();

            codeReader.getVideoInputDevices()
                .then(videoInputDevices => {
                    // 获取摄像头设备列表
                    const constraints = {
                        video: {
                            deviceId: videoInputDevices[this.deviceIdNum].deviceId
                        }
                    };
                    // 使用第一个摄像头设备
                    console.log(constraints)
                    return navigator.mediaDevices.getUserMedia(constraints);
                })
                .then(stream => {
                    // 成功获取摄像头视频流
                    this.videoStream = stream;
                    this.$refs.video.srcObject = stream;
                    this.$refs.video.play();
                })
                .catch(err => {
                    console.error(err);
                });

            this.scan = true;

            codeReader.decodeFromVideoDevice(null, this.$refs.video, (result, err) => {
                if (result) {
                    this.scannedCode = result.getText();
                    if (this.videoStream) {
                        this.videoStream.getTracks().forEach(track => track.stop());
                        this.scan = false;
                    }
                }
            });
        },
        switchScan() {
            if (this.deviceIdNum === 0) {
                this.deviceIdNum = 1
            }
        },
        reqLogistic() {
            axios({
                method: 'post',
                url: 'https://8.130.14.173/admin/InputLogistic',
                params: {
                    'pay_id': this.pay_id,
                    'tracking_number': this.scannedCode,

                }
            })
                .then((response) => {
                    this.successNotify('快递单号录入成功！');
                    location.reload()
                    console.log(response)

                })
                .catch((error) => {
                    this.errorNotify('快递单号录入失败：' + error.response.data.msg);
                });
        },


    },
    beforeMount() {
        this.getOrderData();
    }
}
</script>

<style scoped>

</style>