<template>
    <nut-tour
            v-model="showTabTour"
            :offset="[0, 0]"
            :steps="stepsTap"
            location="top-start"
            maskHeight="60"
            maskWidth="80"
    ></nut-tour>
    <nut-tour
            v-model="showPayTour"
            :offset="[0, 0]"
            :steps="stepsPay"
            bgColor="blue"
            location="bottom-end"
            maskHeight="50"
            maskWidth="300"
            theme="dark"
    ></nut-tour>
    <nut-tour
            v-model="showInfoTour"
            :offset="[0, 0]"
            :steps="stepsInfo"
            bgColor="blue"
            location="bottom-end"
            maskHeight="50"
            maskWidth="300"
            theme="dark"
    ></nut-tour>
    <UserTop @openTour="openTour"/>
    <div v-if="userTabDisplay">
        <nut-backtop :bottom="60"/>

        <div>
            <UserInfo @openTourInfo="openTourInfo"/>
            <Order @openTourPay="openTourPay"/>
            <iti/>
        </div>
        <Footer class="footer"/>


        <UserTab/>
    </div>

</template>

<script>
import UserTop from "@/components/user/UserTop.vue";
import UserTab from "@/components/user/UserTap/UserTap.vue";

import Footer from "@/components/vehicle/Footer.vue";
import UserInfo from "@/components/user/UserTap/info/UserInfo.vue";
import Order from "@/components/user/UserTap/order/Order.vue";
import Iti from "@/components/user/UserTap/iti/Iti.vue";


export default {
    name: "User",
    components: {Iti, Order, UserInfo, Footer, UserTab, UserTop},
    data() {
        return {
            showTabTour: false,
            showInfoTour: false,
            showPayTour: false,
            userTabDisplay: false,
            stepsInfo: [
                {
                    content: '输入数字格式的11位手机号码',
                    target: 'targetPhone',
                },
                {
                    content: '可支持常规格式车牌及新能源车牌',
                    target: 'targetLpn',
                },
                {
                    content: '输入便于进行快递邮寄的地址',
                    target: 'targetAddress',
                },
                {
                    content: '选择您驾驶的车辆类型',
                    target: 'targetVehicleType',
                },
                {
                    content: '选择您驾驶车辆的使用性质',
                    target: 'targetUsingNature',
                },
            ],
            stepsPay: [
                {
                    content: '此处完成服务订购，后请移步到上方订购信息耐心等待发货',
                    target: 'targetPay',
                },
                {
                    content: '收货后请在此处扫描车辆端二维码绑定设备',
                    target: 'targetBind',
                },
            ],
            stepsTap: [
                {
                    content: '新用户请先完善个人信息与紧急联系人信息',
                    target: 'target1'
                },
                {
                    content: '此处订购服务，绑定设备以及查询订单相关',
                    target: 'target2'
                },
                {
                    content: '绑定后查看车辆的行程及预警',
                    target: 'target3',
                    location: 'top-end'
                },

            ]
        }
    },
    methods: {
        openTour() {
            this.showTabTour = true
        },
        openTourPay() {
            this.showPayTour = true
        },
        openTourInfo() {
            this.showInfoTour = true
        },
    },
    beforeMount() {
        const unwatchUserToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                this.userTabDisplay = true;
                unwatchUserToken();
            }
        });
    }
}
</script>

<style scoped>
.footer {
    position: fixed;
    bottom: 50px;
    left: 50px
}
</style>