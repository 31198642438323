<template>
    <nut-popup v-model:visible="visible" :close-on-click-overlay="false" :style="{ padding: '30px 50px' }" round>
        <nut-indicator :block="true" :current="2" :size="2" algin="center">设备激活状态</nut-indicator>
        <div>
            <nut-cell>
                <nut-empty description="设备激活状态">
                    <template #image>
                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <title>icon/母版管理</title>
                            <defs>
                                <circle id="path-1" cx="24" cy="29" r="2"></circle>
                                <filter id="filter-2" filterUnits="objectBoundingBox" height="425.0%" width="425.0%"
                                        x="-162.5%"
                                        y="-137.5%">
                                    <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1"
                                                    stdDeviation="2"></feGaussianBlur>
                                    <feColorMatrix in="shadowBlurOuter1" type="matrix"
                                                   values="0 0 0 0 0.45   0 0 0 0 0.06225   0 0 0 0 0.027  0 0 0 0.2 0"></feColorMatrix>
                                </filter>
                            </defs>
                            <g id="icon/母版管理" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                                <path
                                        id="矩形"
                                        d="M4.5638852,2 L43.4361148,2 C44.3276335,2 44.6509198,2.09282561 44.9768457,2.2671327 C45.3027716,2.4414398 45.5585602,2.69722837 45.7328673,3.0231543 C45.9071744,3.34908022 46,3.67236646 46,4.5638852 L46,43.4361148 C46,44.3276335 45.9071744,44.6509198 45.7328673,44.9768457 C45.5585602,45.3027716 45.3027716,45.5585602 44.9768457,45.7328673 C44.6509198,45.9071744 44.3276335,46 43.4361148,46 L4.5638852,46 C3.67236646,46 3.34908022,45.9071744 3.0231543,45.7328673 C2.69722837,45.5585602 2.4414398,45.3027716 2.2671327,44.9768457 C2.09282561,44.6509198 2,44.3276335 2,43.4361148 L2,4.5638852 C2,3.67236646 2.09282561,3.34908022 2.2671327,3.0231543 C2.4414398,2.69722837 2.69722837,2.4414398 3.0231543,2.2671327 C3.34908022,2.09282561 3.67236646,2 4.5638852,2 Z"
                                        fill="#FD4541" fill-rule="nonzero"></path>
                                <path
                                        id="矩形"
                                        d="M4.5638852,2 L43.4361148,2 C44.3276335,2 44.6509198,2.09282561 44.9768457,2.2671327 C45.3027716,2.4414398 45.5585602,2.69722837 45.7328673,3.0231543 C45.9071744,3.34908022 46,3.67236646 46,4.5638852 L46,14 L46,14 L2,14 L2,4.5638852 C2,3.67236646 2.09282561,3.34908022 2.2671327,3.0231543 C2.4414398,2.69722837 2.69722837,2.4414398 3.0231543,2.2671327 C3.34908022,2.09282561 3.67236646,2 4.5638852,2 Z"
                                        fill="#D8200E" fill-rule="nonzero"></path>
                                <path
                                        id="矩形"
                                        d="M7,6 C8.1045695,6 9,6.8954305 9,8 C9,9.1045695 8.1045695,10 7,10 C5.8954305,10 5,9.1045695 5,8 C5,6.8954305 5.8954305,6 7,6 Z"
                                        fill="#FFFFFF" fill-rule="nonzero"></path>
                                <path
                                        id="矩形-copy-3"
                                        d="M14,6 C15.1045695,6 16,6.8954305 16,8 C16,9.1045695 15.1045695,10 14,10 C12.8954305,10 12,9.1045695 12,8 C12,6.8954305 12.8954305,6 14,6 Z"
                                        fill="#FFFFFF" fill-rule="nonzero"></path>
                                <path
                                        id="矩形-copy-4"
                                        d="M21,6 C22.1045695,6 23,6.8954305 23,8 C23,9.1045695 22.1045695,10 21,10 C19.8954305,10 19,9.1045695 19,8 C19,6.8954305 19.8954305,6 21,6 Z"
                                        fill="#FFFFFF" fill-rule="nonzero"></path>
                                <path
                                        id="合并形状"
                                        d="M25.3121778,22 L26.2655333,24.5415203 C26.3298873,24.5742863 26.3934373,24.6084047 26.456146,24.643838 L29.1243557,24.2009619 L30.6243557,26.7990381 L28.9952076,28.779097 C28.9983915,28.8523336 29,28.925979 29,29 C29,29.074021 28.9983915,29.1476664 28.9952076,29.220903 L30.6243557,31.2009619 L29.1243557,33.7990381 L26.456146,33.356162 C26.3937654,33.3914099 26.3305522,33.4253566 26.2665433,33.4579653 L25.3121778,36 L22.3121778,36 L21.311,33.331 L18.5,33.7990381 L17,31.2009619 L18.81,28.999 L17,26.7990381 L18.5,24.2009619 L21.312,24.668 L22.3121778,22 L25.3121778,22 Z"
                                        fill="#FFFFFF" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </template>
                    <div style="margin-top: 10px; text-align: center">
                        <nut-button plain size="mini" type="danger">
                            <icon-caution fill="#f52f3e" size="12" theme="outline"/>&nbsp;未激活：{{ reason }}
                        </nut-button>
                        <nut-divider/>
                        <nut-animate :loop='true' type='flicker'>
                            <nut-button size="mini" type="info" @click="this.$store.commit('setDetailState', true)">
                                <icon-two-dimensional-code fill="#ffffff" size="12" theme="outline"/>&nbsp;设备码
                            </nut-button>&nbsp;
                            <nut-button onclick="window.location.reload();" size="small" type="success">
                                <icon-refresh fill="#ffffff" size="14" theme="outline"/>&nbsp;刷新
                            </nut-button>
                        </nut-animate>
                    </div>
                </nut-empty>
            </nut-cell>
        </div>
    </nut-popup>
</template>

<script>

export default {
    name: "ActivationFailure",
    data() {
        return {
            visible: false,
            reason: false
        }
    },
    mounted() {
        const unwatchActiveState = this.$watch('$store.state.active_state', function (n) {
            if (n !== true && n !== null) {
                this.visible = true;
                this.reason = n;
                unwatchActiveState();
            }
        });
    }
}
</script>