<template xmlns:bottom="http://www.w3.org/1999/xhtml" xmlns:padding="http://www.w3.org/1999/xhtml">
    <div>
        <!--        添加弹出页-->
        <nut-popup v-model:visible="addContactPopupDisplay" :style="{ height: '20%',width:'80%' }"
                   :teleport-disable="true"
                   round>
            <nut-input
                    v-model="contact_remark"
                    placeholder="备注"
            />
            <nut-input
                    v-model="contact_phone"
                    placeholder="电话号码"
            />
            <nut-button color="#1368D7" size="small" style="margin-left: 110px;margin-top: 10px"
                        @click="addContact">添加
            </nut-button>
        </nut-popup>

        <!--        修改弹出页-->
        <nut-popup v-model:visible="updContactPopupDisplay" :style="{ height: '20%',width:'80%' }"
                   :teleport-disable="true"
                   round>

            <nut-input
                    v-model="select_remark"
                    placeholder="备注"
                    value=""
            />

            <!--            <nut-input
                            v-model="select_phone"
                            placeholder="电话号码"

                        />-->
            <nut-button color="#1368D7" size="small" style="margin-left: 110px;margin-top: 40px"
                        @click="updContact">修改
            </nut-button>

        </nut-popup>

        <!--        删除弹出页-->
        <nut-popup v-model:visible="delContactPopupDisplay" :style="{height: '20%',width:'80%'}"
                   :teleport-disable="true"
                   round>
            <div align="center" style="margin-top: 10px">是否确认删除?</div>
            <nut-button color="#1368D7" size="small" style="margin-left: 100px;margin-top: 60px"
                        @click="delContact">确认删除
            </nut-button>
        </nut-popup>

        <!--控制删除图形显示图标-->
        <nut-cell @click="deleteDisplay">
            <template #icon>
                <icon-people-delete fill="#2c68ff" size="22" theme="outline"/>
            </template>
        </nut-cell>

        <!--遍历Contact-->
        <nut-cell
                v-for="contact in contacts"
                :key="contact.label"
                :desc="contact.phone" :title="contact.remark" @click="reqUpdContact(contact.phone,contact.remark)">
            <template #icon>
                <icon-delete v-if="contactDeleteDisplay" fill="#333" size="14"
                             theme="outline"
                             @click="reqDelContact(contact.phone)"/>
            </template>
        </nut-cell>

        <!--添加图标-->
        <nut-cell title="添加联系人" @click="reqAddContact">
            <template #icon>
                <icon-add-one fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "ContactFind",
    data() {
        return {
            contacts: [
                {
                    remark: null,
                    phone: null,
                }
            ],
            contact_remark: null,
            contact_phone: null,
            addContactPopupDisplay: false,
            updContactPopupDisplay: false,
            select_phone: null,
            select_remark: null,
            contactDeleteDisplay: false,
            delContactPopupDisplay: false
        }
    },

    methods: {
        phoneCheck() {
            if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.contact_phone)) {
                console.log('电话号码格式错误，请重新输入');
                alert('电话号码格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },
        remarkCheck() {
            if (!/^.{2,20}$/.test(this.contact_remark)) {
                console.log('字符数超出限制');
                alert('字符数超出限制')
                return false;
            } else {
                return true;
            }
        },
        updRemarkCheck() {
            if (!/^.{2,20}$/.test(this.select_remark)) {
                console.log('字符数超出限制');
                alert('字符数超出限制')
                return false;
            } else {
                return true;
            }
        },
        getUserInfo() {
            axios({
                method: 'get',
                url: 'https://8.130.14.173/user/reqUser',
                headers: {
                    authorization: this.$store.state.user_token,
                },
            })
                .then((response) => {
                    this.contacts = this.$store.state.contacts
                    console.log(response)
                })
                .catch((error) => {
                    console.log('查询失败')
                    console.log(error)
                });
        },
        deleteDisplay() {
            this.contactDeleteDisplay = !this.contactDeleteDisplay
        },
        addContact() {
            if (!this.phoneCheck()) {
                return;
            }
            if (!this.remarkCheck()) {
                return;
            }
            axios({
                method: 'post',
                url: 'https://8.130.14.173/contact/addContact',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'remark': this.contact_remark,
                    'phone': this.contact_phone,

                }
            })
                .then((response) => {

                    location.reload()
                    this.contacts = this.$store.state.contacts
                    this.addContactPopupDisplay = false
                    console.log(response)
                })
                .catch((error) => {
                    console.log('添加失败')
                    console.log(error)
                });
        },
        updContact() {
            if (!this.updRemarkCheck()) {
                return;
            }

            axios({
                method: 'post',
                url: 'https://8.130.14.173/contact/modify/ContactRemark',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'phone': this.select_phone,
                    'remark': this.select_remark,
                }
            })
                .then((response) => {
                    console.log(response)
                    this.updContactPopupDisplay = false
                    this.contact_remark = this.select_remark
                    location.reload()
                })
                .catch((error) => {
                    console.log('修改失败')
                    console.log(error)
                });
        },
        reqDelContact(phone) {
            this.select_phone = phone
            this.delContactPopupDisplay = true
        },

        delContact() {

            axios({
                method: 'post',
                url: 'https://8.130.14.173/contact/delete/Contact',
                headers: {
                    authorization: this.$store.state.user_token,
                },
                params: {
                    'phone': this.select_phone,
                }
            })
                .then((response) => {
                    location.reload()
                    console.log(response)
                    this.delContactPopupDisplay = false
                })
                .catch((error) => {
                    console.log('修改失败')
                    console.log(error)
                });
        },

        reqAddContact() {
            this.addContactPopupDisplay = true
        },
        reqUpdContact(phone, remark) {

            this.select_phone = phone
            this.select_remark = remark
            if (!this.contactDeleteDisplay)
                this.updContactPopupDisplay = true

        }

    },
    beforeMount() {

        const unwatchContactToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                unwatchContactToken();
            }
        });
    },
    mounted() {
        this.getUserInfo();
    },


}
</script>

<style scoped>

</style>