<template>
    <nut-cell center>
        <nut-image :show-error="true" :src="qrUrl" height="100%"/>
    </nut-cell>
    <nut-cell center>{{ itineraryUuid }}</nut-cell>
</template>

<script>
import QRCode from "qrcode";

export default {
    name: "ItineraryUuidQr",
    data() {
        return {
            itineraryUuid: '行程UUID',
            qrUrl: null
        }
    },
    methods: {
        init() {
            QRCode.toDataURL(this.$store.state.itinerary_uuid, {errorCorrectionLevel: 'H'})
                .then(url => {
                    this.qrUrl = url;
                })
                .catch(err => {
                    console.error(err)
                })
            this.itineraryUuid = this.$store.state.itinerary_uuid;
        }
    },
    mounted() {
        if (this.$store.state.check_state === true) {
            this.init();
        } else {
            const unwatchCheckState = this.$watch('$store.state.check_state', function (n) {
                if (n === true) {
                    this.init();
                    unwatchCheckState();
                }
            });
        }
    },
}
</script>