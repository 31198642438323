<template>
    <nut-audio
            :autoplay="false"
            :loop="false"
            :muted="false"
            :url="url"
            type="icon"
    ></nut-audio>
</template>

<script>
export default {
    name: "WarningAudio",
    props: ['url']
}
</script>

<style>
.nut-icon {
    width: 60%;
}
</style>