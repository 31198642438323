<template>
    <nut-countdown v-model="time" :end-time="timestamp+60*10000">
        <div class="countdown-part-box">
            <div class="part-item">{{ time.s }}s</div>
        </div>
    </nut-countdown>
</template>

<script>
import {reactive, toRefs} from 'vue';

export default {
    name: "WarningCountdown",
    props: ['timestamp'],
    setup(props) {
        const state = reactive({
            time: {
                s: '00'
            }
        });
        return {
            ...toRefs(state)
        };
    }
}
</script>

<style>
.part-item {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e8220e;
    color: #fff;
    font-size: 24px;
    border-radius: 6px;
}
</style>