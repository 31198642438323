<template>
    <div/>
</template>
<script>
import axios from "axios";

export default {
    name: "Monitor",
    methods: {
        sensorMonitor() {
            const g = this.$store.state.acc_g;
            addEventListener('devicemotion', (e) => {
                this.$store.commit('setBaseFreq', e.interval);
                this.$store.commit('setAccX', e.acceleration.x.toFixed(2));
                this.$store.commit('setAccY', e.acceleration.y.toFixed(2));
                this.$store.commit('setAccZ', e.acceleration.z.toFixed(2));
                this.$store.commit('setRotX', e.rotationRate.beta.toFixed(2));
                this.$store.commit('setRotY', e.rotationRate.gamma.toFixed(2));
                this.$store.commit('setRotZ', e.rotationRate.alpha.toFixed(2));
                this.$store.commit('setAccGX', (e.acceleration.x / g).toFixed(2));
                this.$store.commit('setAccGZ', (e.acceleration.z / g).toFixed(2));
                this.$store.commit('setAccGY', (e.acceleration.y / g).toFixed(2));
            });
            addEventListener('deviceorientation', (e) => {
                this.$store.commit('setOriAlpha', e.alpha.toFixed(2));
                this.$store.commit('setOriBeta', e.beta.toFixed(2));
                this.$store.commit('setOriGamma', e.gamma.toFixed(2));
            });
            navigator.geolocation.watchPosition(() => {
                navigator.geolocation.getCurrentPosition((position) => {
                    this.$store.commit('setAccG', (9.780327 * (1 + 0.0053024 * Math.pow(Math.sin(position.coords.latitude), 2) - 0.0000058 * Math.pow(Math.sin(2 * position.coords.latitude), 2))).toFixed(5));
                    if (position.coords.speed !== null) {
                        this.$store.commit('setGeoTs', new Date().getTime());
                        this.$store.commit('setGeoLon', position.coords.longitude.toFixed(5));
                        this.$store.commit('setGeoLat', position.coords.latitude.toFixed(5));
                        this.$store.commit('setGeoAcc', position.coords.accuracy.toFixed(1));
                        this.$store.commit('setGeoAlt', position.coords.altitude.toFixed(1));
                        this.$store.commit('setGeoHeading', position.coords.heading.toFixed(1));
                        this.$store.commit('setGeoSpeed', (position.coords.speed * 3.6).toFixed(2));
                    }
                });
            });
        },
        locMonitor(lon, lat) {
            const tk = '7de2b474a16ad8ce3099e03f22701dba';
            axios({
                method: 'get',
                url: 'https://api.tianditu.gov.cn/geocoder?postStr={"lon":' + lon + ',"lat":' + lat + ',"ver":1}&type=geocode&tk=' + tk,
            })
                .then((response) => {
                    if (response.data.status !== '0') {
                        console.log('Loc Monitor Err', response.data.msg);
                    } else {
                        this.$store.commit('setLocFormatAddr', response.data.result.formatted_address);
                        this.$store.commit('setLocCity', response.data.result.addressComponent.city);
                        this.$store.commit('setLocRoad', response.data.result.addressComponent.road);
                        this.$store.commit('setLocAddr', response.data.result.addressComponent.address);
                    }
                })
                .catch((error) => {
                    console.log('Loc Monitor Err', error.response.data.msg)
                });
        },
        weatherMonitor(city) {
            axios({
                method: 'get',
                url: 'https://api.seniverse.com/v3/weather/now.json',
                params: {
                    'key': 'SVsgQfAhIZwPOW5bo',
                    'location': city,
                },
            })
                .then((response) => {
                    this.$store.commit('setWeatherText', response.data.results[0].now.text);
                    this.$store.commit('setWeatherCode', response.data.results[0].now.code);
                    this.$store.commit('setWeatherTemp', response.data.results[0].now.temperature);
                })
                .catch((error) => {
                    console.log(error)
                    console.log('Weather Monitor Err', error.response.data.msg)
                });
        }
    },
    mounted() {
        const unwatchMonitorState = this.$watch('$store.state.monitor_state', function (n) {
            if (n === true) {
                this.sensorMonitor();
                unwatchMonitorState();
            }
        });
        const unwatchGeoSpeed = this.$watch('$store.state.geo_speed', function (n, o) {
            if (n !== 0 || (n === 0 && o !== n)) {
                this.locMonitor(this.$store.state.geo_lon, this.$store.state.geo_lat);
            }
        });
        const unwatchLocCity = this.$watch('$store.state.loc_city', function (n, o) {
            if (n !== 0 || (n === 0 && o !== n)) {
                this.weatherMonitor(n);
            }
        });
    }
}
</script>