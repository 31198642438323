<template>
    <nut-popup v-model:visible="registerPopupDisplay" :style="{ height: '60%' }" position="bottom">
        <nut-form>
            <nut-form-item label="用户名">
                <nut-input v-model="id" class="nut-input-text" placeholder="请输入用户名" type="text"/>
            </nut-form-item>
            <nut-form-item label="手机号">
                <nut-input v-model="phone" class="nut-input-text" placeholder="请输入手机号" type="text"/>
            </nut-form-item>
            <nut-form-item label="密码">
                <nut-input v-model="password" class="nut-input-text" placeholder="请输入密码" type="password"/>
            </nut-form-item>
        </nut-form>
        <nut-button @click="register">确认注册</nut-button>
    </nut-popup>


    <nut-cell type="primary" @click="reqRegister">注册</nut-cell>
</template>

<script>
import axios from "axios";

export default {
    name: "Register",
    data() {
        return {
            id: null,
            phone: null,
            password: null,
            registerPopupDisplay: false
        }
    },
    methods: {
        phoneCheck() {
            if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.phone)) {
                console.log('电话号码格式错误，请重新输入');
                alert('电话号码格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },
        idCheck() {
            if ((this.id) === "") {
                console.log('用户名为空，请重新输入');
                alert('用户名为空，请重新输入')
                return false;
            } else {
                return true;
            }
        },
        passCheck() {
            if (!/^.{3,20}$/.test(this.password)) {
                console.log('密码格式错误，请重新输入');
                alert('密码码格式错误，请重新输入')
                return false;
            } else {
                return true;
            }
        },
        reqRegister() {
            this.registerPopupDisplay = true;
        },
        register() {
            if (!this.idCheck()) {
                return;
            }
            if (!this.phoneCheck()) {
                return;
            }
            if (!this.passCheck()) {
                return;
            }

            axios({
                method: 'post',
                url: 'https://8.130.14.173/user/register',
                params: {
                    'id': this.id,
                    'phone': this.phone,
                    'password': this.password,
                }
            })
                .then((response) => {
                    alert('注册成功')
                    console.log(response)
                })
                .catch((error) => {
                    alert('注册失败，请重试注册')
                    console.log('注册失败')
                    console.log(error)
                });
        }
    },
}
</script>

<style scoped>

</style>