<template>
    <nut-popup v-model:visible="visible" :close-on-click-overlay="false" :style="{ height: '74%' }" position="top">
        <h2 style="text-align: center">
            <icon-caution fill="#FA2C19" size="22" theme="outline"/>&nbsp;检测到疑似发生事故
        </h2>
        <br>
        <div style="text-align: center">
            <nut-animate :loop='true' type='float'>
                <nut-circle-progress :progress="100" :radius="100" :stroke-width="10" color="#FA2C19"
                                     style="display: inline-block">
                    <nut-countdown ref="warningCountdown" :autoStart="false"
                                   format="ss"
                                   style="font-size: 100px; font-weight: bold; --nut-countdown-display: block"
                                   time="7000"/>
                </nut-circle-progress>
            </nut-animate>
            <br>
            <nut-button v-if="this.$store.state.alert_state===0" size="small" style="margin-top: 25px" type="warning">
                <icon-voice-one fill="#ffffff" size="12" theme="outline"/>&nbsp;待采集音频数据
            </nut-button>
            <nut-button v-if="this.$store.state.alert_state===1" size="small" style="margin-top: 25px" type="danger">
                <icon-voice-one fill="#ffffff" size="12" theme="outline"/>&nbsp;音频数据采集中
            </nut-button>
            <nut-button v-if="this.$store.state.alert_state===2" size="small" style="margin-top: 25px" type="info">
                <icon-voice-one fill="#ffffff" size="12" theme="outline"/>&nbsp;{{ voiceText }}
            </nut-button>
            <div style="margin-top: 20px">
                <nut-button v-if="!cancel_state" shape="square" style="height: 100px; width: 100px; font-size: 30px"
                            type="danger"
                            @click="cancel_state = true">警报取消
                </nut-button>
                <nut-button v-if="cancel_state" disabled shape="square"
                            style="height: 100px; width: 100px; font-size: 30px"
                            type="default">
                    警报取消
                </nut-button>
            </div>
        </div>
    </nut-popup>
</template>

<script>
import Recorder from "js-audio-recorder";
import axios from "axios";
import {ref} from "vue";

export default {
    name: "Warning",
    setup() {
        const warningCountdown = ref(null);
        const startWarningCountdown = () => {
            if (warningCountdown.value !== null) {
                warningCountdown.value.start();
            }
        };
        const resetWarningCountdown = () => {
            if (warningCountdown.value !== null) {
                warningCountdown.value.reset();
            }
        };
        return {warningCountdown, startWarningCountdown, resetWarningCountdown};
    },
    data() {
        return {
            visible: false,
            voiceText: '音频数据已采集',
            cancel_state: false,
            askAudio: new Audio(require('@/assets/audio/alertAsk.wav')),
            startAudio: new Audio(require('@/assets/audio/alertStart.wav')),
            finAudio: new Audio(require('@/assets/audio/alertFin.wav')),
        }
    },
    mounted() {
        const unwatchAlertState = this.$watch('$store.state.alert_state', (n) => {
            this.stopAudio();
            this.resetWarningCountdown();
            this.cancel_state = false;
            if (n === 0) {
                this.finAudio.play();
                this.visible = false;
                this.$store.commit('setWarningState', false);
                this.$store.commit('setDangerState', false);
            }
            if (n === 1) {
                this.visible = true;
                this.askAudio.play();
                setTimeout(() => {
                    this.daq();
                }, 2000)
            }
            if (n === 2) {
                this.visible = false;
            }
            if (n === 3) {
                this.alertStart();
            }
        });
    },
    methods: {
        alertStart() {
            this.startAudio.loop = true;
            this.startAudio.play();
        },
        stopAudio() {
            this.askAudio.pause();
            this.startAudio.pause();
            this.finAudio.pause();
        },
        daq() {
            this.startWarningCountdown();

            const recorder = new Recorder({
                sampleBits: 16,
                sampleRate: 16000,
                numChannels: 1,
            });
            recorder.start().then(() => {
                setTimeout(() => {
                    new Promise((resolve, reject) => {
                        recorder.stop();
                        if (!this.cancel_state) {
                            const wav = recorder.getWAVBlob();
                            const pcm = recorder.getPCMBlob();
                            new Promise((resolve) => {
                                axios({
                                    method: 'post',
                                    url: 'https://8.130.14.173/vehicle/warning/recording/upload',
                                    headers: {
                                        authorization: this.$store.state.token,
                                        'Content-Type': 'multipart/form-data'
                                    },
                                    data: {
                                        file: wav
                                    }
                                })
                                    .then((response) => {
                                        this.$store.commit('setAlertRecording', response.data.data);
                                        resolve();
                                    })
                                    .catch((error) => {
                                        console.log('Recording Upload Err: ' + error.response.data);
                                        resolve();
                                    });
                            })
                                .then((recorder) => {
                                    axios({
                                        method: 'post',
                                        url: 'https://eastasia.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=zh-CN',
                                        headers: {
                                            'Ocp-Apim-Subscription-Key': '18862e4af41f45ec9a0c24c554d88468',
                                            'Content-Type': 'audio/wav; codecs=audio/pcm; samplerate=16000',
                                            'Accept': 'application/json'
                                        },
                                        data: pcm
                                    })
                                        .then((response) => {
                                            if (!this.cancel_state) {
                                                const text = response.data.DisplayText;
                                                this.voiceText = text;
                                                if (text.search('不是') !== -1 || text.search('没有') !== -1 || text.search('否') !== -1) {
                                                    this.$store.commit('setAlertState', 0);
                                                } else {
                                                    this.$store.commit('setAlertState', 2);
                                                }
                                            } else {
                                                this.$store.commit('setAlertState', 0);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log('Azure Speech Cognitive Err: ' + error.response.data);
                                        });
                                })
                        } else {
                            this.$store.commit('setAlertState', 0);
                        }
                        resolve();
                    }).then(() => {
                        recorder.destroy();
                    })
                }, 7700)
            }, (error) => {
                console.log(`${error.name} : ${error.message}`);
            });
        }
    }
}
</script>