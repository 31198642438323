<template>
  <!--遍历Itinerary-->
    <nut-cell
            v-for="itinerary in iti_data"
            :key="itinerary.label"
            :desc="itinerary.start_time"
            @click="ItiMainInfo(itinerary.id,itinerary.device,itinerary.start_time,itinerary.end_time)"
    >
        <template #icon>
            <icon-protect v-if="itinerary.end_time!=null" fill="green" size="24" theme="outline"/>

            <icon-attention v-if="itinerary.end_time==null" fill="#FFEA66" size="24" theme="outline"/>
        </template>
    </nut-cell>

  <!--行程弹出页-->
    <nut-popup v-model:visible="ItiMainInfoPopupDisplay" :style="{height: '60%',width:'90%'}" :teleport-disable="true"
               round>
        <nut-cell :desc="iti_uuid" title="行程UUID">
            <template #icon>
                <icon-steering-wheel fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="iti_device" title="设备UUID">
            <template #icon>
                <icon-steering-wheel fill="#333" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="iti_start_time" title="开始时间">
            <template #icon>
                <icon-hourglass-null fill="green" size="24" theme="outline"/>
            </template>
        </nut-cell>
        <nut-cell :desc="iti_end_time" title="结束时间">
            <template #icon>
                <icon-hourglass-full fill="red" size="24" theme="outline"/>
            </template>
        </nut-cell>

    </nut-popup>

</template>

<script>
import axios from "axios";

export default {
    name: "ItineraryFind",
    data() {
        return {
            iti_data: [
                {
                    id: null,
                    device: null,
                    start_time: null,
                    end_time: null,
                }
            ],
            ItiMainInfoPopupDisplay: false,
            iti_uuid: null,
            iti_device: null,
            iti_start_time: null,
            iti_end_time: null,
            noneEndDisplay: false,
        }
    },
    methods: {
        ItiMainInfo(id, device, start_time, end_time) {
            this.iti_uuid = id
            this.iti_device = device
            this.iti_start_time = start_time
            this.iti_end_time = end_time
            this.ItiMainInfoPopupDisplay = true
        },

        getItiInfo() {

            axios({
                method: 'get',
                url: 'https://8.130.14.173/user/UserItinerary',
                headers: {
                    authorization: this.$store.state.user_token,
                },
            })
                .then((response) => {
                    this.$store.commit('setItiData', response.data.data)
                    this.iti_data = this.$store.state.iti_data
                })
                .catch((error) => {
                    console.log('查询失败')
                    console.log(error)
                });
        }
    },
    mounted() {
        this.getItiInfo()
    },
    beforeMount() {

        const unwatchContactToken = this.$watch('$store.state.user_token', function (n) {
            if (n) {
                unwatchContactToken();
            }
        });
    },

}
</script>

<style scoped>

</style>