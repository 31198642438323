<template>
    <VConsole/>
    <div v-if="$route.fullPath === '/'">
        <router-link to="/">
            <nut-button type="primary">首页</nut-button>
        </router-link>&nbsp;
        <router-link to="/vehicle">
            <nut-button type="primary">车辆端</nut-button>
        </router-link>&nbsp;
        <router-link to="/user">
            <nut-button type="primary">用户端</nut-button>
        </router-link>&nbsp;
        <router-link to="/admin">
            <nut-button type="primary">管理端</nut-button>
        </router-link>&nbsp;

    </div>

    <router-view v-if="$route.fullPath !== '/'" :key="$route.fullPath"></router-view>
</template>

<script>

import VConsole from "@/components/VConsole.vue";

export default {
    name: 'App',
    components: {
        VConsole
    }
}
</script>
