import {createApp} from "vue";
import App from "./App.vue";
import NutUI, {Picker, Tour} from "@nutui/nutui";
import "@nutui/nutui/dist/styles/themes/default.scss";
import {install} from '@icon-park/vue-next/es/all';
import "@icon-park/vue-next/styles/index.css"
import {createRouter, createWebHistory} from "vue-router";
import {createStore} from "vuex";
import * as echarts from "echarts";
import 'echarts/map/js/china'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import Vehicle from "@/pages/Vehicle.vue";
import SRC from "@/pages/SRC.vue";
import IFinish from "@/components/vehicle/IFinish.vue";
import IDetail from "@/components/vehicle/IDetail.vue";
import User from "@/pages/User.vue";
import AdminOrder from "@/components/admin/AdminOrder.vue";
import Test from "@/components/test/maptest.vue";
import Admin from "@/pages/Admin.vue";

const routes = [
    {path: '/', name: 'index', component: App},
    {path: '/vehicle', name: 'vehicle', component: Vehicle},
    {path: '/vehicle/finish', name: 'vehicle_finish', component: IFinish},
    {path: '/i', name: 'vehicle_detail', component: IDetail},
    {path: '/src', name: 'src', component: SRC},
    {path: '/adminOrder', name: 'adminOrder', component: AdminOrder},
    {path: '/user', name: 'user', component: User},
    {path: '/test', name: 'test', component: Test},
    {path: '/admin', name: 'admin', component: Admin},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const store = createStore({
    state() {
        return {
            // 设备激活状态
            active_state: null,
            // 系统详情状态
            detail_state: null,
            // 系统自检状态
            check_state: null,
            // 监测状态
            monitor_state: null,
            // 消息队列连接状态
            mq_state: null,
            // 传感数据标签页状态
            sensor_tab: false,
            // 地理信息标签页状态
            geo_tab: false,
            // 其他信息标签页状态
            other_tab: false,
            // 状态标签页状态
            status_tab: true,
            // 常规监测结果时间戳
            normal_ts: null,
            // 常规监测概率
            normal_pr: null,
            // 预警状态
            // 正常-0; 数据采集(应答期)-1; 异常(等待期)-2; 异常(已下达指令)-3;
            alert_state: 0,
            // 预警状态
            warning_state: false,
            // 事故预警时间戳
            alert_ts: null,
            // 事故预警应答录音
            alert_recording: null,
            // 警报状态
            danger_state: false,

            // 鉴权Token
            token: null,

            // 基本数据
            // 设备UUID
            device_uuid: null,
            // 行程唯一识别码
            itinerary_uuid: null,
            // 用户名
            user: null,
            // 紧急联系人数据
            contacts: null,
            //预警时间戳
            ref_timestamp: null,

            // 天气状况
            // 天气
            weather_text: null,
            // 天气代码
            weather_code: null,
            // 温度
            weather_temp: null,

            // 位置信息
            // 格式化地址
            loc_format_addr: null,
            // 城市
            loc_city: null,
            // 道路
            loc_road: null,
            // 地址
            loc_addr: null,

            // 传感器频率
            base_freq: null,

            // G值
            // 重力加速度
            acc_g: null,
            // 前向加速度
            acc_g_z: null,
            // 侧向加速度
            acc_g_x: null,
            // 垂直加速度
            acc_g_y: null,

            // 加速度
            // X轴
            acc_x: null,
            // Y轴
            acc_y: null,
            // Z轴
            acc_z: null,

            // 偏转度
            // 设备朝向
            ori_alpha: null,
            // 正向角度
            ori_beta: null,
            // 侧向角度
            ori_gamma: null,

            // 地理信息
            // 地理信息时间戳
            geo_ts: null,
            // 经度
            geo_lon: null,
            // 纬度
            geo_lat: null,
            // 位置精度
            geo_acc: null,
            // 海拔
            geo_alt: null,
            // 方向
            geo_heading: null,
            // 速度
            geo_speed: null,

            // 旋转速率
            // X轴
            rot_x: null,
            // Y轴
            rot_y: null,
            // Z轴
            rot_z: null,

            // ===== SRC中心 =====
            // 连接状态
            src_state: null,
            // 暂停状态
            src_pause_state: null,
            // 最新预警信息
            src_warning_latest: null,
            // 预警详情信息
            src_warning_detail: null,
            // 预警信息删除标记
            src_delete_flag: null,
            // 预警信息详情展示
            src_detail_display: null,

            // ==== i行程中心 ====
            // 当前查询ID
            i_uuid: null,

            // ==== 用户端 ====
            //用户信息
            user_info: null,
            //用户Token
            user_token: null,
            //用户名
            user_id: null,
            //手机号
            user_phone: null,
            //地址
            user_address: null,
            //选择器地址
            choose_address: null,
            //车牌
            user_lpn: null,
            //联系人手机号
            contact_phone: null,
            //联系人备注
            contact_remark: null,
            //使用性质
            using_nature: null,
            //使用性质
            vehicle_type: null,
            //个人信息标签页状态
            info_tab: null,
            //服务相关标签页状态
            order_tab: null,
            //智能客服标签页状态
            ai_tab: null,
            //行程信息标签页状态
            iti_tab: null,
            //查询uuid
            iti_uuid: null,
            //查询设备id
            iti_device: null,
            //查询行程开始时间
            iti_start_time: null,
            //查询行程结束时间
            iti_end_time: null,
            //查询行程数据
            iti_data: null,
            //查询行程预警数据
            alarm_data: null,
            //查询订单数据
            order_data: null,
            //订单编号
            order_id: null,
            //订单状态
            order_status: null,
            //订单地址
            order_address: null,
            //订单电话
            order_phone: null,
            //快递单号
            tracking_number: null,
            //下单时间
            pay_time: null,
            //查询快递数据
            logistic_data: null,
            //查询快递状态
            logistic_deliverystatus: null,
            //查询快递名称
            logistic_expName: null,
            //查询快递官网
            logistic_expSite: null,
            //查询快递电话
            logistic_expPhone: null,
            //查询快递最新时间
            logistic_update_time: null,
            //查询快递花费时间
            logistic_take_time: null,
            //查询快递图标
            logistic_logo: null,
            //查询快递详情
            logistic_list: null,
            //跳转支付网址
            pay_url: null,
            //支付订单编号
            pay_id: null,
            //支付服务月数
            pay_month: null,
            //管理员token
            admin_token: null,
        }
    },
    mutations: {
        setDeviceUUID(state, value) {
            state.device_uuid = value;
        },
        setActiveState(state, value) {
            state.active_state = value;
        },
        setToken(state, value) {
            state.token = value;
        },
        setUser(state, value) {
            state.user = value;
        },
        setDetailState(state, value) {
            state.detail_state = value;
        },
        setCheckState(state, value) {
            state.check_state = value;
        },
        setMonitorState(state, value) {
            state.monitor_state = value;
        },
        setItineraryUUID(state, value) {
            state.itinerary_uuid = value;
        },
        setMqState(state, value) {
            state.mq_state = value;
        },
        setSensorTab(state, value) {
            state.sensor_tab = value;
        },
        setAccX(state, value) {
            state.acc_x = Number(value);
        },
        setAccY(state, value) {
            state.acc_y = Number(value);
        },
        setAccZ(state, value) {
            state.acc_z = Number(value);
        },
        setOriAlpha(state, value) {
            state.ori_alpha = Number(value);
        },
        setOriBeta(state, value) {
            state.ori_beta = Number(value);
        },
        setOriGamma(state, value) {
            state.ori_gamma = Number(value);
        },
        setAccG(state, value) {
            state.acc_g = Number(value);
        },
        setAccGZ(state, value) {
            state.acc_g_z = Number(value);
        },
        setAccGX(state, value) {
            state.acc_g_x = Number(value);
        },
        setRotX(state, value) {
            state.rot_x = Number(value);
        },
        setRotY(state, value) {
            state.rot_y = Number(value);
        },
        setRotZ(state, value) {
            state.rot_z = Number(value);
        },
        setGeoTs(state, value) {
            state.geo_ts = value;
        },
        setGeoLon(state, value) {
            state.geo_lon = Number(value);
        },
        setGeoLat(state, value) {
            state.geo_lat = Number(value);
        },
        setGeoAcc(state, value) {
            state.geo_acc = Number(value);
        },
        setGeoAlt(state, value) {
            state.geo_alt = Number(value);
        },
        setGeoHeading(state, value) {
            state.geo_heading = Number(value);
        },
        setGeoSpeed(state, value) {
            state.geo_speed = Number(value);
        },
        setGeoTab(state, value) {
            state.geo_tab = value;
        },
        setBaseFreq(state, value) {
            state.base_freq = Number(value);
        },
        setLocFormatAddr(state, value) {
            state.loc_format_addr = value;
        },
        setLocCity(state, value) {
            state.loc_city = value;
        },
        setLocRoad(state, value) {
            state.loc_road = value;
        },
        setLocAddr(state, value) {
            state.loc_addr = value;
        },
        setOtherTab(state, value) {
            state.other_tab = value;
        },
        setWeatherText(state, value) {
            state.weather_text = value;
        },
        setWeatherTemp(state, value) {
            state.weather_temp = Number(value);
        },
        setWeatherCode(state, value) {
            state.weather_code = Number(value);
        },
        setAccGY(state, value) {
            state.acc_g_y = Number(value);
        },
        setNormalTs(state, value) {
            state.normal_ts = Number(value);
        },
        setNormalPr(state, value) {
            state.normal_pr = Number(value);
        },
        setStatusTab(state, value) {
            state.status_tab = value;
        },
        setContacts(state, value) {
            state.contacts = value;
        },
        setRefTimestamp(state, value) {
            state.ref_timestamp = value;
        },
        setAlertState(state, value) {
            state.alert_state = value;
        },
        setWarningState(state, value) {
            state.warning_state = value;
        },
        setDangerState(state, value) {
            state.danger_state = value;
        },
        setAlertTs(state, value) {
            state.alert_ts = value;
        },
        setAlertRecording(state, value) {
            state.alert_recording = value;
        },

        // ===== SRC响应中心 =====
        setSrcState(state, value) {
            state.src_state = value;
        },
        setSrcPauseState(state, value) {
            state.src_pause_state = value;
        },
        setSrcWarningLatest(state, value) {
            state.src_warning_latest = value;
        },
        setSrcWarningDetail(state, value) {
            state.src_warning_detail = value;
        },
        setSrcDeleteFlag(state, value) {
            state.src_delete_flag = value;
        },
        setSrcDetailDisplay(state, value) {
            state.src_detail_display = !state.src_detail_display;
        },
        setIUuid(state, value) {
            state.i_uuid = value;
        },


        // ===== 用户端 =====
        setUserInfo(state, value) {
            state.user_info = value;
        },
        setUserToken(state, value) {
            state.user_token = value;
        },
        setUserId(state, value) {
            state.user_id = value;
        },
        setUserPhone(state, value) {
            state.user_phone = value;
        },
        setUserAddress(state, value) {
            state.user_address = value;
        },
        setChooseAddress(state, value) {
            state.choose_address = value;
        },
        setUserLpn(state, value) {
            state.user_lpn = value;
        },
        setContactPhone(state, value) {
            state.contact_phone = value;
        },
        setContactRemark(state, value) {
            state.contact_remark = value;
        },
        setUsingNature(state, value) {
            state.using_nature = value;
        },
        setVehicleType(state, value) {
            state.vehicle_type = value;
        },
        setInfoTab(state, value) {
            state.info_tab = value;
        },
        setOrderTab(state, value) {
            state.order_tab = value;
        },
        setAITab(state, value) {
            state.ai_tab = value;
        },
        setItiTab(state, value) {
            state.iti_tab = value;
        },
        setItiUuid(state, value) {
            state.iti_uuid = value;
        },
        setItiDevice(state, value) {
            state.iti_device = value;
        },
        setItiStartTime(state, value) {
            state.iti_start_time = value;
        },
        setItiEndTime(state, value) {
            state.iti_end_time = value;
        },
        setItiData(state, value) {
            state.iti_data = value;
        },
        setAlarmData(state, value) {
            state.alarm_data = value;
        },
        setOrderData(state, value) {
            state.order_data = value;
        },
        setOrderId(state, value) {
            state.order_id = value;
        },
        setOrderStatus(state, value) {
            state.order_status = value;
        },
        setOrderPhone(state, value) {
            state.order_phone = value;
        },
        setOrderAddress(state, value) {
            state.order_address = value;
        },
        setTrackingNumber(state, value) {
            state.tracking_number = value;
        },
        setPayTime(state, value) {
            state.pay_time = value;
        },
        setLogisticData(state, value) {
            state.logistic_data = value;
        },
        setLogisticDeliveryStatus(state, value) {
            state.logistic_deliverystatus = value;
        },
        setLogisticExpName(state, value) {
            state.logistic_expName = value;
        },
        setLogisticExpSite(state, value) {
            state.logistic_expSite = value;
        },
        setLogisticExpPhone(state, value) {
            state.logistic_expPhone = value;
        },
        setLogisticUpdateTime(state, value) {
            state.logistic_update_time = value;
        },
        setLogisticTakeTime(state, value) {
            state.logistic_take_time = value;
        },
        setLogisticLogo(state, value) {
            state.logistic_logo = value;
        },
        setLogisticList(state, value) {
            state.logistic_list = value;
        },
        setPayUrl(state, value) {
            state.pay_url = value;
        },
        setPayId(state, value) {
            state.pay_id = value;
        },
        setPayMonth(state, value) {
            state.pay_month = value;
        },
        setAdminToken(state, value) {
            state.admin_token = value;
        },
    }
})

function useTable(app) {
    app.use(VXETable);
}

const app = createApp(App);

install(app);

app.config.globalProperties.$echarts = echarts;

app.use(NutUI).use(router).use(store).use(useTable).use(Picker).use(Tour).mount("#app");
